import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'insig-booking-new-tia-patient-terms-dialog',
  templateUrl: './new-tia-patient-terms-dialog.component.html',
})
export class NewTiaPatientTermsDialogComponent {
  public titleLine = 'Terms & Conditions';

  constructor(public dialogRef: MatDialogRef<NewTiaPatientTermsDialogComponent>) {}
}
