import { Component, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { TranslateService } from '@insig-health/services/translate/translate.service';

@Component({
  selector: 'insig-booking-language-sidenav',
  templateUrl: './language-sidenav.component.html',
})
export class LanguageSidenavComponent {
  @Input() sidenav: MatSidenav | undefined;

  constructor(
    public translateService: TranslateService,
  ) { }

  handleLanguageClicked(language: string): void {
    this.translateService.changeLanguage(language);
    this.sidenav?.close();
  }
}
