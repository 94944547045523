import { Component } from '@angular/core';
import { ClinicService, Clinic } from '../../services/clinic/clinic.service';

@Component({
  selector: 'insig-booking-clinic-selection',
  templateUrl: './clinic-selection.component.html',
  styleUrls: ['./clinic-selection.component.scss'],
})
export class ClinicSelectionComponent {

  public clinics: Clinic[];

  constructor(private clinicService: ClinicService) {
    this.clinics = this.clinicService.getMockClinics();
  }

}
