import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';

import { PatientProfileService } from '@insig-health/services/patient-profile/patient-profile.service';
import { BookingStepService } from '../../services/booking-step/booking-step.service';
import { CompanyBookingComponent } from '../../app/company-booking/company-booking.component';
import { DraftBookingComponent } from '../../app/draft-booking/draft-booking.component';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';

@Injectable({
  providedIn: 'root',
})
export class IsProfileCompleteGuard implements CanActivate {

  constructor(
    private gcpIpAuthService: GcpIpAuthService,
    private patientProfileService: PatientProfileService,
    private bookingStepService: BookingStepService,
  ) {}

  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const deepestRoute = this.bookingStepService.getDeepestActivatedRouteChild(state.root);
    const draftBookingComponentRoute = this.bookingStepService.getActivatedRouteOfComponentType(deepestRoute, DraftBookingComponent);
    const draftAppointmentId = draftBookingComponentRoute.params.draftAppointmentId;
    const isPatientProfileComplete = await this.isPatientProfileComplete();

    if (!isPatientProfileComplete && draftAppointmentId) {
      const companyBookingRoute = this.bookingStepService.getActivatedRouteOfComponentType(state.root, CompanyBookingComponent);

      return this.bookingStepService.getUrlTreeForLoginStep({
        relativeTo: companyBookingRoute,
        pathParams: {
          draftAppointmentId: draftAppointmentId,
        },
      });
    } else if (!draftAppointmentId) {
      return false;
    } else {
      return true;
    }
  }

  private async isPatientProfileComplete(): Promise<boolean> {
    const patientUid = await this.gcpIpAuthService.getCurrentUser()?.uid;
    if (patientUid === undefined) {
      return false;
    }
    return this.patientProfileService.isPatientProfileComplete(patientUid);
  }
}
