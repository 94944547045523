<div *ngIf="loadingDoctors" class="position-absolute top-50 start-50 translate-middle z-index-500">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>

<div
  class="h-100 d-flex flex-column"
  [ngClass]="{ 'blur pe-none': loadingDoctors }"
>
  <ng-container *ngTemplateOutlet="mobileAppointmentTypeSelection"></ng-container>
  <div class="min-h-0 d-flex flex-column d-md-grid gap-0 column-gap-md-4 grid-cols-1 grid-cols-md-2 px-md-5">
    <ng-container *ngTemplateOutlet="appointmentTypeSelector"></ng-container>
    <ng-container *ngTemplateOutlet="doctorSelector"></ng-container>
  </div>
  <ng-container *ngTemplateOutlet="noResultsScreen; context:{ doctorsAndFacets: (doctorsAndFacets$ | async) }"></ng-container>
</div>

<ng-template #noResultsScreen let-doctorsAndFacets="doctorsAndFacets">
  <div 
    *ngIf="!(areThereAvailableServices(doctorsAndFacets) || areThereAvailableDoctors(doctorsAndFacets)) && !loadingDoctors"
    class="d-flex flex-column text-center gap-3 pt-5 px-2 font-size-xxl textSecondary bold"
  >
    <div *ngIf="doctorSearchQuery">Sorry, we couldn't find any available healthcare providers matching the term "{{ doctorSearchQuery }}".</div>
    <ng-container *ngIf="!doctorSearchQuery && (chooseDoctorRouteParams.appointmentType || chooseDoctorRouteParams.doctorId)">
      <div>Sorry, no healthcare providers are available for the selected appointment type or doctor.</div>
      <insig-ui-button stylePreset="secondary" class="mx-auto w-fit-content" (click)="handleClearFiltersClicked()">Clear selections</insig-ui-button>
    </ng-container>
    <ng-container *ngIf="!doctorSearchQuery && !(chooseDoctorRouteParams.appointmentType || chooseDoctorRouteParams.doctorId)">
      <div>Sorry, no healthcare providers are available at the moment. Please check back later.</div>
      <div>If this is an emergency please go to your nearest emergency centre immediately.</div>
    </ng-container>
  </div>
</ng-template>

<ng-template #mobileAppointmentTypeSelection>
  <ng-container *ngIf="chooseDoctorRouteParams.appointmentType !== undefined">
    <insig-ui-expansion-panel #expansionPanel class="d-block d-md-none sticky-top" [flushStyle]="true">
      <div header class="bold">Your Selections</div>
      <div description *ngIf="expansionPanel.state === InsigExpansionPanelState.COLLAPSED"
        class="w-100 bold textGreen lineClampedText"
      >
        {{ chooseDoctorRouteParams.appointmentType }}
      </div>
      <div content class="d-flex flex-column bold">
        <ng-container *ngIf="chooseDoctorRouteParams.appointmentType !== undefined">
          <div class="w-100 d-flex justify-content-between">
            <div class="d-flex">
              <span class="textSecondary">Type:&nbsp;</span>
              <span class="textGreen lineClampedText">{{ chooseDoctorRouteParams.appointmentType }}</span>
            </div>
            <ng-container *ngTemplateOutlet="editButton"></ng-container>
          </div>
        </ng-container>
      </div>
    </insig-ui-expansion-panel>
  </ng-container>
</ng-template>

<ng-template #appointmentTypeSelector>
  <div insigBookingScrollbarPadding class="d-flex flex-column overflow-auto">
    <div *ngIf="chooseDoctorRouteParams.appointmentType === undefined">
      <insig-booking-quick-book
        #quickBook
        *ngIf="(doctorsAndFacets$ | async)?.doctorSearchData as doctorSearchData"
        class="d-block d-sm-none"
        [doctorSearchData]="doctorSearchData"
        [selectedAppointmentType]="chooseDoctorRouteParams.appointmentType"
        [companyId]="chooseDoctorRouteParams.companyId"
        [billingType]="chooseDoctorRouteParams.billingType"
        (quickBookClicked)="handleQuickBookClicked(quickBook.quickBookDoctorSearchData)"
      >
      </insig-booking-quick-book>
    </div>

    <div class="sticky-md-top lightBackground">
      <h1 class="d-none d-md-block">What's your reason for visit?</h1>
      <h1 *ngIf="chooseDoctorRouteParams.appointmentType === undefined" class="d-block d-md-none">
        What's your reason for visit?
      </h1>
    </div>

    <p *ngIf="chooseDoctorRouteParams.appointmentType === undefined" class="mt-1 mb-3" role="doc-subtitle">Choose an appointment type</p>

    <div>
      <insig-booking-focus-mental-wellness
        *ngIf="chooseDoctorRouteParams.appointmentType === 'Mental Health' && chooseDoctorRouteParams.companyId === 'tiaHealth'"
        class="d-md-none"
        [billingType]="chooseDoctorRouteParams.billingType"
      ></insig-booking-focus-mental-wellness>
      <ng-container *ngTemplateOutlet="appointmentTypeList"></ng-container>
    </div>
  </div>

  <ng-template #appointmentTypeList>    
    <div *ngIf="chooseDoctorRouteParams.appointmentType !== undefined" class="d-none d-md-block mb-2 pb-2 font-size-md">
      <div>Appointment Type Selected:</div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="textGreen bold">{{ chooseDoctorRouteParams.appointmentType }}</div>
        <ng-container *ngTemplateOutlet="editButton"></ng-container>
      </div>
    </div>
    
    <ng-container *ngIf="appointmentTypes$ | async as appointmentTypes">
      <div
        class="d-grid grid-cols-1 grid-cols-sm-2 gap-1 gap-md-3 pb-4"
        [ngClass]="{ 'd-none': chooseDoctorRouteParams.appointmentType !== undefined }"
      >
        <button
          *ngFor="let appointmentType of appointmentTypes"
          class="fw-bold p-3 drop-shadow transparent-border rounded blueBackground greenOnHover greenOnFocus"
          (click)="handleAppointmentTypeClicked(appointmentType)"
        >
          <div *ngIf="doctorsAndFacets$ | async as doctorsAndFacets" class="d-flex flex-column w-100 gap-2">
            <div class="d-flex">
              <span class="flex-fill text-center font-size-sm">{{ appointmentType }}</span>
              <ng-container *ngIf="chooseDoctorRouteParams.billingType === BillingType.PRIVATE || getIsPublicServiceChargedByAppointmentTypeName(appointmentType, doctorsAndFacets?.doctorSearchData ?? [])">
                <ng-container
                  [ngTemplateOutlet]="appointmentTypePriceTag"
                  [ngTemplateOutletContext]="{ 'appointmentTypeName': appointmentType }"
                >
                </ng-container>
              </ng-container>
            </div>
            <insig-booking-availability-label
              *ngIf="chooseDoctorRouteParams.doctorId !== undefined &&
                !isServiceAvailableOnSameDayAsDoctorEarliestAvailableDate(chooseDoctorRouteParams.doctorId, appointmentType, doctorsAndFacets) &&
                getEarliestAvailableDateForDoctorAppointment(chooseDoctorRouteParams.doctorId, appointmentType, doctorsAndFacets) as serviceEarliestDate"
              class="d-flex flex-shrink mx-auto"
              [date]="serviceEarliestDate"></insig-booking-availability-label>
          </div>
        </button>
      </div>
    </ng-container>
  </ng-template>

</ng-template>

<ng-template #doctorSelector>
  <div class="position-relative overflow-auto px-2" [ngClass]="{ 'd-none d-md-block': chooseDoctorRouteParams.appointmentType === undefined }">
    <div class="h-100 d-flex flex-column"> 
      <div class="d-grid align-items-center mb-2">
        <div>
          <h1>Practitioners Available</h1>
          <div class="d-flex gap-2 mt-2">
            <p role="doc-subtitle" class="my-auto text-nowrap">Choose a practitioner</p>
            <insig-booking-search #searchComponent class="flex-grow-1" (searchInputChanged)="handleSearchInputChanged($event)"></insig-booking-search>
          </div>
        </div>
      </div>

      <insig-booking-focus-mental-wellness
        *ngIf="chooseDoctorRouteParams.appointmentType === 'Mental Health' && chooseDoctorRouteParams.companyId === 'tiaHealth'"
        class="d-none d-md-block"
        [billingType]="chooseDoctorRouteParams.billingType"
      ></insig-booking-focus-mental-wellness>

      <insig-booking-quick-book 
        #quickBook
        *ngIf="(doctorsAndFacets$ | async)?.doctorSearchData as doctorSearchData"
        [doctorSearchData]="doctorSearchData"
        [selectedAppointmentType]="chooseDoctorRouteParams.appointmentType"
        [companyId]="chooseDoctorRouteParams.companyId"
        [billingType]="chooseDoctorRouteParams.billingType"
        (quickBookClicked)="handleQuickBookClicked(quickBook.quickBookDoctorSearchData)"
      >
      </insig-booking-quick-book>

      <div
        class="d-grid grid-cols-2 grid-cols-md-1 grid-cols-xl-2 gap-3 overflow-auto pb-4"
      >
        <insig-booking-doctor-card
          *ngFor="let doctor of (doctorsAndFacets$ | async)?.doctorSearchData ?? []"
          class="text-start"
          [doctor]="doctor"
          [selected]="doctor.doctorMetadata.id === chooseDoctorRouteParams.doctorId"
          [selectedAppointmentType]="chooseDoctorRouteParams.appointmentType"
          (doctorCardClicked)="handleDoctorCardClicked(doctor)"
        >
        </insig-booking-doctor-card>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editButton>
  <button class="d-flex align-items-center font-size-md" (click)="handleEditAppointmentTypeClicked()">
    <img class="d-inline-block me-1" src="assets/img/ico-edit-pencil.svg" alt="Edit">
    <span class="bold textBlue">Edit</span>
  </button>
</ng-template>

<ng-template #appointmentTypePriceTag let-appointmentTypeName='appointmentTypeName'>
  <span class="notranslate">
    <ng-container *ngIf="getPriceRangeByAppointmentTypeName(appointmentTypeName, (doctorsAndFacets$ | async)) as priceRange">
      <ng-container *ngIf="priceRange.minimumPrice === priceRange.maximumPrice">
        &nbsp;${{ priceRange.minimumPrice }}
      </ng-container>
      <ng-container *ngIf="priceRange.minimumPrice !== priceRange.maximumPrice">
        &nbsp;${{ priceRange.minimumPrice }} - ${{ priceRange.maximumPrice }}
      </ng-container>
    </ng-container>
  </span>
</ng-template>
