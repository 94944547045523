import { Injectable } from '@angular/core';
import { PatientRegistrationService as GeneratedPatientRegistrationService, SuccessResponse, PatientRegistrationV2, PatientRegistrationInfo } from '@insig-health/api/patient-registration-api';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PatientRegistrationService {
  constructor(
    private generatedPatientRegistrationService: GeneratedPatientRegistrationService,
  ) {}

  registerPatient(patientInformation: PatientRegistrationV2): Promise<SuccessResponse> {
    return lastValueFrom(this.generatedPatientRegistrationService.createPatientV2(patientInformation));
  }

  updatePatientInformation(patientInformation: PatientRegistrationInfo): Promise<SuccessResponse> {
    return lastValueFrom(this.generatedPatientRegistrationService.updatePatientRegistration(patientInformation));
  }
}
