import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { AppointmentMedium, AppointmentMediumService } from 'apps/insig-booking/src/services/appointment-medium/appointment-medium.service';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';
import { DoctorSearchData } from '@insig-health/services/doctor/doctor.service';

import { AppointmentReservationService, DraftAppointmentSlot } from 'apps/insig-booking/src/services/appointment-reservation/appointment-reservation.service';

@Component({
  selector: 'insig-booking-confirm-appointment-details',
  templateUrl: './confirm-appointment-details.component.html',
  styleUrls: ['./confirm-appointment-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmAppointmentDetailsComponent {
  @Input() doctorSearchData: DoctorSearchData | undefined;
  @Input() draftAppointment: DraftAppointmentSlot | undefined;
  @Input() serviceType: string | undefined;
  @Input() servicePrice: number | undefined;
  @Input() discountedPrice: number | undefined;

  @Output() editButtonClicked = new EventEmitter();

  constructor(
    private appointmentMediumService: AppointmentMediumService,
    private appointmentReservationService: AppointmentReservationService,
    private dateAndTimeService: DateAndTimeService,
  ) { }

  getFormattedStartDateAndTime(utcTimestamp: number | undefined): string {
    if (utcTimestamp === undefined) {
      return '';
    } else {
      const date = this.getDateFromUtcTimestamp(utcTimestamp);
      const timezone = this.dateAndTimeService.getLocalTimeZone();
      return this.dateAndTimeService.getDateAndTimeInLocalizedFormat(date, timezone);
    }
  }

  getDateFromUtcTimestamp(utcTimestamp: number): Date {
    return new Date(utcTimestamp);
  }

  getFormattedAppointmentMedium(appointmentMediumString: string | undefined): string {
    const appointmentMedium = this.appointmentMediumService.parseAppointmentMedium(appointmentMediumString);
    if (appointmentMedium) {
      return this.appointmentMediumService.getFormattedAppointmentMedium(appointmentMedium);
    } else {
      return '';
    }
  }

  handleAppointmentMediumSelected(appointmentMedium: AppointmentMedium): void {
    if (this.draftAppointment?.appointmentId !== undefined) {
      const { appointmentId, patientId } = this.draftAppointment;
      this.appointmentReservationService.updateReservedAppointmentSlot(appointmentId, {
        patientId: patientId,
        serviceMedium: appointmentMedium,
      });
    }
  }

  handleEditButtonClicked(): void {
    this.editButtonClicked.emit();
  }

  getServicePriceText(servicePrice: number): string {
    return `$${servicePrice}`;
  }

  getDoctorTitleAndName(doctor: DoctorSearchData | undefined): string {
    if (doctor?.doctorMetadata?.title) {
      return `${doctor.doctorMetadata.title} ${doctor.doctorMetadata.fullName}`;
    } else {
      return `${doctor?.doctorMetadata?.fullName}`;
    }
  }

  getBookingRestrictionsMessage(doctor: DoctorSearchData | undefined): string {
    return doctor?.service?.bookingRestrictions?.message ?? '';
  }
}
