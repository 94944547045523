import { OnInit, Component, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentServiceMedium } from '@insig-health/api/doctor-booking-flow-api-v1';
import { Appointment, DoctorSearchData } from '@insig-health/services/doctor/doctor.service';
import { DoctorCardDialogComponent } from './doctor-card-dialog/doctor-card-dialog.component';

@Component({
  selector: 'insig-booking-doctor-card',
  templateUrl: './doctor-card.component.html',
  styleUrls: ['./doctor-card.component.scss'],
})
export class DoctorCardComponent implements OnInit {
  @Input() doctor: DoctorSearchData = {
    doctorMetadata: {
      id: '',
      companyId: '',
      fullName: '',
      specialty: '',
      languagesSpoken: [],
      bookingInstructions: '',
      credentials: '',
      profilePictureUrl: '',
      patientRating: 0,
      onTimeRating: 0,
    },
    availabilityData: {},
    service: {
      bookingRestrictions: null,
      mediums: [],
      appointments: [],
    },
    earliestAvailableDate: new Date(),
  };

  @Input() selected = false;
  @Input() selectedAppointmentType: string | undefined;

  @Output() doctorCardClicked = new EventEmitter<void>();

  public AppointmentServiceMedium = AppointmentServiceMedium;

  public nextDateDoctorIsAvailable: Date | undefined;

  private loadingDoctorSearchData: DoctorSearchData = {
    doctorMetadata: {
      id: 'loading',
      companyId: '',
      fullName: 'loading',
      specialty: 'loading',
      languagesSpoken: [],
      bookingInstructions: 'loading',
      credentials: '',
      profilePictureUrl: '',
      patientRating: 0,
      onTimeRating: 0,
    },
    earliestAvailableDate: new Date(),
    availabilityData: {},
    service: {
      bookingRestrictions: null,
      mediums: [],
      appointments: [],
    },
  };

  constructor(private dialog: MatDialog) {
    this.doctor = this.loadingDoctorSearchData;
  }

  ngOnInit(): void {
    this.nextDateDoctorIsAvailable = this.getNextDateDoctorIsAvailable(this.doctor, this.selectedAppointmentType);
  }

  handleDoctorCardClicked(): void {
    this.doctorCardClicked.emit();
  }

  getNextDateDoctorIsAvailable(doctorSearchData: DoctorSearchData, selectedAppointmentType: string | undefined): Date | undefined {
    let appointment: Appointment | undefined;
    if (selectedAppointmentType) {
      appointment = doctorSearchData.service.appointments.find((appointment) => appointment.type === selectedAppointmentType);
    }

    if (appointment?.earliestAvailabilityForAppointment) {
      return appointment.earliestAvailabilityForAppointment;
    } else {
      return doctorSearchData.earliestAvailableDate;
    }
  }

  handleReadMoreClicked($event: Event): void {
    $event.stopPropagation();
    this.dialog.open(DoctorCardDialogComponent, {
      ...DoctorCardDialogComponent.DEFAULT_DIALOG_CONFIG,
      data: { doctorProfile: this.doctor.doctorMetadata },
    });
  }

  doesDoctorOfferServicesInThisMedium(doctor: DoctorSearchData, medium: AppointmentServiceMedium): boolean {
    return doctor.service.appointments.some((appointment) => appointment.mediums.includes(medium));
  }
}
