import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { FormValidatorsService } from '@insig-health/services/form-validators/form-validators.service';
import { HealthCardService } from 'apps/insig-booking/src/services/health-card/health-card.service';
import { RegexService } from '@insig-health/services/regex/regex.service';

import { FamilyMemberProfile } from '@insig-health/services/family-member/family-member.service';
import { Gender, PatientProfile } from '@insig-health/services/patient-profile/patient-profile.service';

@Component({
  selector: 'insig-booking-patient-profile-form',
  templateUrl: './patient-profile-form.component.html',
})
export class PatientProfileFormComponent implements OnInit {

  public static readonly PATIENT_PROFILE_FORM_NAME = 'patientInfoForm';

  public readonly FIRST_NAME_FORM_CONTROL_NAME = 'firstName';
  public readonly LAST_NAME_FORM_CONTROL_NAME = 'lastName';
  public readonly DATE_OF_BIRTH_FORM_CONTROL_NAME = 'dateOfBirth';
  public readonly GENDER_FORM_CONTROL_NAME = 'gender';
  public readonly STREET_ADDRESS_FORM_CONTROL_NAME = 'streetAddress';
  public readonly CITY_FORM_CONTROL_NAME = 'city';
  public readonly PROVINCE_FORM_CONTROL_NAME = 'province';
  public readonly POSTAL_CODE_FORM_CONTROL_NAME = 'postalCode';
  public readonly PHONE_NUMBER_FORM_CONTROL_NAME = 'phoneNumber';
  public readonly HEALTH_CARD_NUMBER_FORM_CONTROL_NAME = 'healthCardNumber';
  public readonly FAMILY_DOCTOR_FIRST_NAME_FORM_CONTROL_NAME = 'familyDoctorFirstName';
  public readonly FAMILY_DOCTOR_LAST_NAME_FORM_CONTROL_NAME = 'familyDoctorLastName';
  public readonly FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME = 'familyDoctorFaxNumber';

  @Input() parentForm: UntypedFormGroup | undefined;
  @Input() patientProfile: PatientProfile | FamilyMemberProfile | undefined;

  public readonly patientProfileForm = this.formBuilder.group({
    [this.FIRST_NAME_FORM_CONTROL_NAME]: ['', [Validators.required]],
    [this.LAST_NAME_FORM_CONTROL_NAME]: ['', [Validators.required]],
    [this.DATE_OF_BIRTH_FORM_CONTROL_NAME]: ['', [Validators.required, this.formValidatorsService.isDateValid, this.formValidatorsService.isDateInCorrectFormat]],
    [this.GENDER_FORM_CONTROL_NAME]: ['', [Validators.required]],
    [this.STREET_ADDRESS_FORM_CONTROL_NAME]: ['', [Validators.required]],
    [this.CITY_FORM_CONTROL_NAME]: ['', [Validators.required]],
    [this.PROVINCE_FORM_CONTROL_NAME]: ['', [Validators.required]],
    [this.POSTAL_CODE_FORM_CONTROL_NAME]: ['', [Validators.required, this.formValidatorsService.isPostalCodeValid]],
    [this.PHONE_NUMBER_FORM_CONTROL_NAME]: ['', [Validators.required, this.formValidatorsService.isPhoneNumberValidValidator(false)]],
    [this.HEALTH_CARD_NUMBER_FORM_CONTROL_NAME]: [''],
    [this.FAMILY_DOCTOR_FIRST_NAME_FORM_CONTROL_NAME]: [''],
    [this.FAMILY_DOCTOR_LAST_NAME_FORM_CONTROL_NAME]: [''],
    [this.FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME]: ['', [this.formValidatorsService.isPhoneNumberValidValidator(true)]],
  });

  public today = new Date();
  public readonly healthCardProvinces = this.healthCardService.getHealthCardProvinces();

  public genders = Object.values(Gender);

  constructor(
    private formBuilder: FormBuilder,
    private formValidatorsService: FormValidatorsService,
    private healthCardService: HealthCardService,
  ){}

  ngOnInit(): void {
    if (this.parentForm) {
      this.registerFormAsParentToChild(this.parentForm, this.patientProfileForm, PatientProfileFormComponent.PATIENT_PROFILE_FORM_NAME);
    }

    if (this.patientProfile) {
      this.prepopulatePharmacyInfoFormFromPatientProfile(this.patientProfileForm, this.patientProfile);
    }
  }

  registerFormAsParentToChild(parentForm: UntypedFormGroup, childForm: UntypedFormGroup, childFormName: string): void {
    parentForm.addControl(childFormName, childForm);
    childForm.setParent(parentForm);
  }

  prepopulatePharmacyInfoFormFromPatientProfile(form: UntypedFormGroup, patientProfile: PatientProfile | FamilyMemberProfile): void {
    form.patchValue({
      [this.FIRST_NAME_FORM_CONTROL_NAME]: patientProfile.firstName,
      [this.LAST_NAME_FORM_CONTROL_NAME]: patientProfile.lastName,
      [this.DATE_OF_BIRTH_FORM_CONTROL_NAME]: patientProfile.birthdate,
      [this.GENDER_FORM_CONTROL_NAME]: patientProfile.gender,
      [this.STREET_ADDRESS_FORM_CONTROL_NAME]: patientProfile.address,
      [this.CITY_FORM_CONTROL_NAME]: patientProfile.city,
      [this.PROVINCE_FORM_CONTROL_NAME]: patientProfile.province,
      [this.POSTAL_CODE_FORM_CONTROL_NAME]: patientProfile.postalCode,
      [this.PHONE_NUMBER_FORM_CONTROL_NAME]: this.formatPhoneNumberForDisplay(patientProfile.phone),
      [this.HEALTH_CARD_NUMBER_FORM_CONTROL_NAME]: patientProfile.healthCardNumber,
      [this.FAMILY_DOCTOR_FIRST_NAME_FORM_CONTROL_NAME]: patientProfile.familyDoctorFirstName ?? '',
      [this.FAMILY_DOCTOR_LAST_NAME_FORM_CONTROL_NAME]: patientProfile.familyDoctorLastName ?? '',
      [this.FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME]: patientProfile.familyDoctorFaxNumber ? this.formatPhoneNumberForDisplay(patientProfile.familyDoctorFaxNumber) : '',
    });
  }

  formatPhoneNumberForDisplay(phoneNumber: string): string {
    if (phoneNumber.match(RegexService.PHONE_NUMBER_REGEX)) {
      return phoneNumber;
    }

    const areaCode = phoneNumber.slice(0, 3);
    const telephonePrefix = phoneNumber.slice(3, 6);
    const lineNumber = phoneNumber.slice(6, 10);

    return `(${areaCode}) ${telephonePrefix}-${lineNumber}`;
  }
}
