<ng-container [formGroup]="patientProfileForm">
    <!-- #region name -->
    <div class="d-flex w-100">
      <div class="d-flex flex-column w-50 py-2 pe-2">
        <insig-ui-text-input
          class="py-2"
          label="First Name"
          [formControlName]="FIRST_NAME_FORM_CONTROL_NAME"
          isRequired="true"
        ></insig-ui-text-input>
        <insig-ui-form-error
          [formControlForErrorMessage]="patientProfileForm.controls[FIRST_NAME_FORM_CONTROL_NAME]"
          errorText="First name is required."
          errorType="required"
        ></insig-ui-form-error>
      </div>
  
      <div class="d-flex flex-column w-50 py-2 ps-2">
        <insig-ui-text-input
          class="py-2"
          label="Last Name"
          [formControlName]="LAST_NAME_FORM_CONTROL_NAME"
          isRequired="true"
        ></insig-ui-text-input>
        <insig-ui-form-error
          [formControlForErrorMessage]="patientProfileForm.controls[LAST_NAME_FORM_CONTROL_NAME]"
          errorText="Last name is required."
          errorType="required"
        ></insig-ui-form-error>
      </div>
    </div>
    <!-- #endregion name -->
  
    <!-- #region birthday and gender -->
    <div class="d-flex w-100">
      <div class="d-flex flex-column w-50 py-2 pe-2">
        <insig-ui-select class="py-2" label="Gender at Birth" isRequired="true" [formControlName]="GENDER_FORM_CONTROL_NAME">
          <option
            *ngFor="let gender of genders"
            class="bold"
            [value]="gender"
          >
            {{ gender }}
          </option>
        </insig-ui-select>
        <insig-ui-form-error
          [formControlForErrorMessage]="patientProfileForm.controls[GENDER_FORM_CONTROL_NAME]"
          [errorText]="'Gender is required.'"
          [errorType]="'required'"
        ></insig-ui-form-error>
      </div>

      <div class="d-flex flex-column w-50 py-2 ps-2">
        <insig-ui-date-picker 
          class="py-2" 
          label="Date of Birth" 
          isDisabled="false" 
          [formControlName]="DATE_OF_BIRTH_FORM_CONTROL_NAME" 
          [maxDate]="today" 
          isRequired="true"
        ></insig-ui-date-picker>
        <insig-ui-form-error
          [formControlForErrorMessage]="patientProfileForm.controls[DATE_OF_BIRTH_FORM_CONTROL_NAME]"
          errorText="Date of Birth is required."
          errorType="required"
        ></insig-ui-form-error>
        <insig-ui-form-error
          [formControlForErrorMessage]="patientProfileForm.controls[DATE_OF_BIRTH_FORM_CONTROL_NAME]"
          errorText="Date of Birth must be a valid date."
          errorType="birthdateIsNotValid"
        ></insig-ui-form-error>
        <insig-ui-form-error
          [formControlForErrorMessage]="patientProfileForm.controls[DATE_OF_BIRTH_FORM_CONTROL_NAME]"
          errorText="Date of Birth must be in the format YYYY-MM-DD."
          errorType="birthdateNotInCorrectFormat"
        ></insig-ui-form-error>
      </div>
    </div>
    <!-- #endregion birthday and gender -->
  
    
    <div class="d-flex flex-wrap w-100">
      <!-- #region address-->
      <div class="d-flex flex-column w-50 w-md-33 py-2 pe-2">
        <insig-ui-text-input
          class="py-2"
          label="Address"
          [formControlName]="STREET_ADDRESS_FORM_CONTROL_NAME"
          isRequired="true"
        ></insig-ui-text-input>
        <insig-ui-form-error
          [formControlForErrorMessage]="patientProfileForm.controls[STREET_ADDRESS_FORM_CONTROL_NAME]"
          errorText="Street address is required."
          errorType="required"
        ></insig-ui-form-error>
      </div>
      <!-- #endregion address -->

      <!-- #region city, province and postal code-->
      <div class="d-flex flex-column w-50 w-md-33 py-2 ps-2 pe-md-2">
        <insig-ui-text-input
          class="py-2"
          label="City"
          [formControlName]="CITY_FORM_CONTROL_NAME"
          isRequired="true"
        ></insig-ui-text-input>
        <insig-ui-form-error
          [formControlForErrorMessage]="patientProfileForm.controls[CITY_FORM_CONTROL_NAME]"
          errorText="City is required."
          errorType="required"
        ></insig-ui-form-error>
      </div>

      <div class="d-flex flex-column w-50 w-md-33 py-2 pe-2 pe-md-0 ps-md-2">
        <insig-ui-select class="py-2" label="Province" isRequired="true" [formControlName]="PROVINCE_FORM_CONTROL_NAME">
          <option 
            *ngFor="let healthCardProvince of healthCardProvinces"
            [value]="healthCardProvince"
          >
            {{ healthCardProvince }}
          </option>
        </insig-ui-select>
        <insig-ui-form-error
          [formControlForErrorMessage]="patientProfileForm.controls[PROVINCE_FORM_CONTROL_NAME]"
          errorText="Province is required."
          errorType="required"
        ></insig-ui-form-error>
      </div>
  
      <div class="d-flex flex-column w-50 w-md-33 py-2 ps-2 ps-md-0 pe-md-2">
        <insig-ui-text-input
          class="py-2"
          label="Zip/Postal Code"
          [formControlName]="POSTAL_CODE_FORM_CONTROL_NAME"
          isRequired="true"
        ></insig-ui-text-input>
        <insig-ui-form-error
          [formControlForErrorMessage]="patientProfileForm.controls[POSTAL_CODE_FORM_CONTROL_NAME]"
          errorText="Postal Code is required."
          errorType="required"
        ></insig-ui-form-error>
        <insig-ui-form-error
          [formControlForErrorMessage]="patientProfileForm.controls[POSTAL_CODE_FORM_CONTROL_NAME]"
          errorText="Postal Code must be valid."
          errorType="postalCodeNotValid"
        ></insig-ui-form-error>
      </div>
      <!-- #endregion city, province and postal code -->

      <!-- #region phone number -->
      <div class="d-flex flex-column w-50 w-md-33 py-2 pe-2 ps-md-2">
        <insig-ui-text-input
          insig-phone-mask
          class="py-2"
          label="Phone Number"
          [formControlName]="PHONE_NUMBER_FORM_CONTROL_NAME"
          isRequired="true"
        ></insig-ui-text-input>
        <insig-ui-form-error
          [formControlForErrorMessage]="patientProfileForm.controls[PHONE_NUMBER_FORM_CONTROL_NAME]"
          errorText="Phone number is required."
          errorType="required"
        ></insig-ui-form-error>
        <insig-ui-form-error
          [formControlForErrorMessage]="patientProfileForm.controls[PHONE_NUMBER_FORM_CONTROL_NAME]"
          errorText="Phone number must be valid"
          errorType="phoneNumberNotValid"
        ></insig-ui-form-error>
      </div>
      <!-- #endregion phone number -->

      <!-- #region health card number-->
      <div class="d-flex flex-column w-50 w-md-33 py-2 ps-2">
        <insig-ui-text-input
          class="py-2"
          label="Health Card Number"
          [formControlName]="HEALTH_CARD_NUMBER_FORM_CONTROL_NAME"
        ></insig-ui-text-input>
      </div>
      <!-- #endregion health card number -->
    </div>

    <!-- #region family doctor -->
    <div class="d-flex flex-wrap w-100">
      <insig-ui-text-input
        class="w-50 w-md-33 pe-2"
        label="Family Doctor First Name"
        [formControlName]="FAMILY_DOCTOR_FIRST_NAME_FORM_CONTROL_NAME"
      ></insig-ui-text-input>
      <insig-ui-text-input
        class="w-50 w-md-33 ps-2 px-md-2"
        label="Family Doctor Last Name"
        [formControlName]="FAMILY_DOCTOR_LAST_NAME_FORM_CONTROL_NAME"
      ></insig-ui-text-input>
      <insig-ui-text-input
        class="w-100 w-md-33 px-0 pt-2 ps-md-2 pt-md-0"
        label="Family Doctor Fax Number"
        insig-phone-mask
        [formControlName]="FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME"
      ></insig-ui-text-input>
    </div>
    <!-- #endregion family doctor -->
</ng-container>
