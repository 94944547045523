import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { DraftBookingComponent } from '../../app/draft-booking/draft-booking.component';
import { ProvinceBookingComponent } from '../../app/province-booking/province-booking.component';
import { Province, ProvinceService } from '@insig-health/services/province/province.service';
import { combineLatest, from, merge, Observable, of as observableOf } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { AppointmentReservationService } from '../appointment-reservation/appointment-reservation.service';
import { BookingStepService } from '../booking-step/booking-step.service';
import { LocationService, Region } from '@insig-health/services/location/location.service';

@Injectable({
  providedIn: 'root',
})
export class CurrentProvinceService {

  private currentRegion$ = merge(
    observableOf(undefined),
    (this.router.events as Observable<RouterEvent>).pipe(filter(this.isNavigationEnd)),
  ).pipe(
    switchMap((_): Observable<Region> => {
      const deepestRoute = this.bookingStepService.getDeepestActivatedRouteChild(this.activatedRoute);
      try {
        const province$ = this.getProvinceFromProvinceParam(deepestRoute);
        return this.getRegionObservable(province$);
      } catch (_error) {
        try {
          const province$ = this.getProvinceFromDraftAppointmentIdParam(deepestRoute);
          return this.getRegionObservable(province$);
        } catch (_error) {
          return from(this.locationService.getRegion().catch(() => ({
            countryAbbreviation: 'CA',
            regionName: 'Ontario',
            regionAbbreviation: Province.ON,
          })));
        }
      }
    }),
    distinctUntilChanged(),
    shareReplay(1),
  );

  constructor(
    private bookingStepService: BookingStepService,
    private provinceService: ProvinceService,
    private appointmentReservationService: AppointmentReservationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private locationService: LocationService,
  ) { }

  getCurrentRegion(): Observable<Region> {
    return this.currentRegion$;
  }

  getRegionList(): Observable<Region[]> {
    const provinceList = Object.values(Province);
    const regionList = provinceList.map((provinceAbbreviation) => (
      {
        countryAbbreviation: 'CA',
        regionName: this.provinceService.getProvinceName(provinceAbbreviation),
        regionAbbreviation: provinceAbbreviation.toString(),
      }
    ));

    return this.currentRegion$.pipe(
      map((currentRegion) => {
        if (!regionList.some((region) => region.regionAbbreviation === currentRegion.regionAbbreviation)) {
          regionList.splice(0, 0, currentRegion);
        }
        return regionList;
      }),
    );
  }

  isNavigationEnd(routerEvent: RouterEvent): routerEvent is NavigationEnd {
    return routerEvent instanceof NavigationEnd;
  }

  getProvinceFromProvinceParam(activatedRoute: ActivatedRoute): Observable<Province> {
    const provinceBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(activatedRoute, [ProvinceBookingComponent]);
    return provinceBookingRoute.params.pipe(
      map((params) => this.provinceService.parseQueryParamProvince(params.provinceAbbreviation)),
    );
  }

  getProvinceFromDraftAppointmentIdParam(activatedRoute: ActivatedRoute): Observable<Province> {
    const draftBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(activatedRoute, [DraftBookingComponent]);
    return draftBookingRoute.params.pipe(
      switchMap((params) => this.appointmentReservationService.getReservedAppointmentSlot(params.draftAppointmentId)),
      map((draftAppointment) => draftAppointment.province),
    );
  }

  getRegionObservable(province$: Observable<Province>): Observable<Region> {
    return combineLatest([
      province$,
      province$.pipe(map((province) => this.provinceService.getProvinceName(province))),
    ]).pipe(map(([provinceAbbreviation, provinceName]) => ({
      countryAbbreviation: 'CA',
      regionName: provinceName,
      regionAbbreviation: provinceAbbreviation,
    })));
  }
}
