import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyBookingComponent } from '../company-booking/company-booking.component';
import { Subscription, firstValueFrom } from 'rxjs';
import { AppointmentReservationService, DraftAppointmentSlot } from '../../services/appointment-reservation/appointment-reservation.service';
import { BookingStep, BookingStepService } from '../../services/booking-step/booking-step.service';
import { CompanyDataService } from '@insig-health/services/company/company-data.service';
import { ProvinceBookingComponent } from '../province-booking/province-booking.component';
import { BillingTypeBookingComponent } from '../billing-type-booking/billing-type-booking.component';
import { BillingTypeService } from '../../services/billing-type/billing-type.service';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';
import { ThemeService } from '../../services/theme/theme.service';
import { BillingRegionService } from '../../services/billing-region/billing-region.service';
import { MatDialog } from '@angular/material/dialog';
import { PROVINCE_SELECTION_DIALOG_CONFIG, ProvinceSelectionDialogComponent, ProvinceSelectionDialogOptions } from '../choose-doctor/components/province-selection-dialog/province-selection-dialog.component';
import { CurrentProvinceService } from '../../services/current-province/current-province.service';

@Component({
  selector: 'insig-booking-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  public BookingStep = BookingStep;

  public doctorId: string | undefined;
  public showTimer = false;
  public isLoggedIn$ = this.gcpIpAuthService.isLoggedIn();

  private _currentReservedAppointmentSubscription: Subscription;
  public currentReservedAppointmentSlot: DraftAppointmentSlot | undefined;
  private _currentBookingStepChangedSubscription: Subscription;
  public currentBookingStep: BookingStep | undefined;

  public companyBranding: string | undefined;
  public isRegionSelectorVisible = false;
  public themeLogoUrl = this.themeService.getCurrentThemeConfig().header.logoUrl;

  public currentRegion$ = this.currentProvinceService.getCurrentRegion();

  @Input() isSidenavOpen = false;

  @Output() toggleSidenavClicked = new EventEmitter<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private gcpIpAuthService: GcpIpAuthService,
    private bookingStepService: BookingStepService,
    private appointmentReservationService: AppointmentReservationService,
    private companyDataService: CompanyDataService,
    private billingTypeService: BillingTypeService,
    private billingRegionService: BillingRegionService,
    private themeService: ThemeService,
    private dialog: MatDialog,
    private currentProvinceService: CurrentProvinceService,
    public router: Router,
  ) {
    this._currentReservedAppointmentSubscription = this.appointmentReservationService
      .getCurrentReservedAppointmentSlot()
      .subscribe((currentReservedAppointment) => {
        this.handleCurrentReservedAppointmentChanged(currentReservedAppointment);
      });

    this._currentBookingStepChangedSubscription = this.bookingStepService.bookingStepChange$.subscribe((bookingStep) => {
      this.currentBookingStep = bookingStep;

      const showRegionSelectorBasedOnTheme = this.themeService.getCurrentThemeConfig().isRegionSelectorVisible;
      const showRegionSelectorBasedOnBookingStep = bookingStep === BookingStep.CHOOSE_DOCTOR || bookingStep === BookingStep.CHOOSE_TIME;
      this.isRegionSelectorVisible = showRegionSelectorBasedOnTheme && showRegionSelectorBasedOnBookingStep;
    });
  }

  async ngOnInit(): Promise<void> {
    this.companyBranding = await this.getCompanyBranding();
  }

  ngOnDestroy(): void {
    this._currentReservedAppointmentSubscription.unsubscribe();
    this._currentBookingStepChangedSubscription.unsubscribe();
  }

  async getCompanyBranding(): Promise<string | undefined> {
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.activatedRoute, [CompanyBookingComponent]);
    const companyId = companyBookingRoute.snapshot.params.companyId;
    const company = await this.companyDataService.getCompanyData(companyId);
    return company.branding;
  }

  handleLoginClicked(): void {
    this.router.navigate(['login'], { relativeTo: this.bookingStepService.getDeepestActivatedRouteChild(this.activatedRoute), queryParamsHandling: 'merge' });
  }

  async handleLogoClicked(): Promise<void> {
    const currentReservedAppointment = await firstValueFrom(this.appointmentReservationService.getCurrentReservedAppointmentSlot());

    if (currentReservedAppointment !== undefined) {
      this.navigateToChooseDoctorByReservedAppointment(currentReservedAppointment, this.activatedRoute);
    } else {
      this.navigateToChooseDoctorByRoute(this.activatedRoute);
    }
  }

  navigateToChooseDoctorByRoute(activatedRoute: ActivatedRoute): void {
    const deepestRouteChild = this.bookingStepService.getDeepestActivatedRouteChild(activatedRoute);
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(deepestRouteChild, [CompanyBookingComponent]);
    const provinceBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(deepestRouteChild, [ProvinceBookingComponent]);
    const provinceAbbreviation =  provinceBookingRoute.snapshot.params.provinceAbbreviation;
    const billingTypeBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(deepestRouteChild, [BillingTypeBookingComponent]);
    const billingType = this.billingTypeService.parseBillingType(billingTypeBookingRoute.snapshot.params.billingType);
    const region = this.billingRegionService.getBillingRegion(provinceAbbreviation, billingType);
    this.bookingStepService.jumpToStep(BookingStep.CHOOSE_DOCTOR, {
      navigationExtras: { relativeTo: companyBookingRoute },
      pathParams: { region },
    });
  }

  navigateToChooseDoctorByReservedAppointment(reservedAppointment: DraftAppointmentSlot, activatedRoute: ActivatedRoute): void {
    const deepestRouteChild = this.bookingStepService.getDeepestActivatedRouteChild(activatedRoute);
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(deepestRouteChild, [CompanyBookingComponent]);
    const billingType = this.billingTypeService.parseBillingType(reservedAppointment.billingType);
    const region = this.billingRegionService.getBillingRegion(reservedAppointment.province, billingType);
    this.bookingStepService.jumpToStep(BookingStep.CHOOSE_DOCTOR, {
      navigationExtras: { relativeTo: companyBookingRoute },
      pathParams: { region },
    });
  }

  isLoginButtonAvailable(): boolean {
    return this.bookingStepService.isLoginAvailable(this.activatedRoute);
  }

  handleCurrentReservedAppointmentChanged(currentReservedAppointment: DraftAppointmentSlot | undefined): void {
    this.showTimer = currentReservedAppointment !== undefined;
    this.currentReservedAppointmentSlot = currentReservedAppointment;
  }

  handleQuickConnectClicked(): void {
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.activatedRoute, [CompanyBookingComponent]);
    this.router.navigate(['quickConnect'], { relativeTo: companyBookingRoute });
  }

  handleToggleSidenavClicked(): void {
    this.toggleSidenavClicked.emit();
  }

  async handleRegionSelectorClicked(): Promise<void> {
    this.dialog.open<ProvinceSelectionDialogComponent, ProvinceSelectionDialogOptions>(
      ProvinceSelectionDialogComponent,
      {
        ...PROVINCE_SELECTION_DIALOG_CONFIG,
        data: { replaceUrl: false },
      },
    );
  }
}
