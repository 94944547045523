import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'insig-booking-company-booking',
  templateUrl: './company-booking.component.html',
  styleUrls: ['./company-booking.component.scss'],
})
export class CompanyBookingComponent {
  public companyId: string;

  constructor(
    private route: ActivatedRoute,
  ) {
    this.companyId = this.route.snapshot.params.companyId;
  }
}
