import { Directive, HostListener, OnDestroy } from '@angular/core';
import { CompanyBookingComponent } from '../../app/company-booking/company-booking.component';
import { Subscription } from 'rxjs';
import { AppointmentReservationService, DraftAppointmentSlot } from '../../services/appointment-reservation/appointment-reservation.service';
import { BookingStep, BookingStepService } from '../../services/booking-step/booking-step.service';
import { BillingTypeService } from '../../services/billing-type/billing-type.service';
import { BillingRegionService } from '../../services/billing-region/billing-region.service';
import { ActivatedRoute } from '@angular/router';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';

@Directive({
  standalone: true,
  selector: '[insigBookingLogoutButton]',
})
export class LogoutButtonDirective implements OnDestroy {
  private currentReservedAppointmentSlot: DraftAppointmentSlot | undefined;
  private _currentReservedAppointmentSubscription: Subscription;

  constructor(
    private bookingStepService: BookingStepService,
    private billingTypeService: BillingTypeService,
    private billingRegionService: BillingRegionService,
    private appointmentReservationService: AppointmentReservationService,
    private gcpIpAuthService: GcpIpAuthService,
    private activatedRoute: ActivatedRoute,
  ) {
    this._currentReservedAppointmentSubscription = this.appointmentReservationService
      .getCurrentReservedAppointmentSlot()
      .subscribe((currentReservedAppointmentSlot) => {
        this.currentReservedAppointmentSlot = currentReservedAppointmentSlot;
      });
  }

  ngOnDestroy(): void {
    this._currentReservedAppointmentSubscription.unsubscribe();
  }

  @HostListener('click')
  async handleHostClicked(): Promise<void> {
    if (this.currentReservedAppointmentSlot !== undefined) {
      const billingType = this.billingTypeService.parseBillingType(this.currentReservedAppointmentSlot.billingType);
      const region = this.billingRegionService.getBillingRegion(this.currentReservedAppointmentSlot.province, billingType);
      const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.activatedRoute, [CompanyBookingComponent]);
      await this.bookingStepService.jumpToStep(BookingStep.CHOOSE_TIME, {
        navigationExtras: {
          relativeTo: companyBookingRoute,
        },
        pathParams: {
          region,
          doctorId: this.currentReservedAppointmentSlot.doctorId,
          serviceId: this.currentReservedAppointmentSlot.serviceId,
        },
        queryParams: {
          redirectedFromLogout: 'true',
        },
      });
    } else {
      await this.gcpIpAuthService.signOut();
    }
  }
}
