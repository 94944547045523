import { Component, HostBinding, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { CompanyBookingComponent } from '../../company-booking/company-booking.component';
import { AppointmentReservationService, DraftAppointmentSlot } from 'apps/insig-booking/src/services/appointment-reservation/appointment-reservation.service';
import { BookingStep, BookingStepService } from 'apps/insig-booking/src/services/booking-step/booking-step.service';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/angular-material.config';
import { BillingTypeService } from 'apps/insig-booking/src/services/billing-type/billing-type.service';
import { MILLISECONDS_PER_MINUTE, MILLISECONDS_PER_SECOND } from '@insig-health/services/date-and-time/date-and-time.constants';
import { MatDialog } from '@angular/material/dialog';
import { BillingRegionService } from 'apps/insig-booking/src/services/billing-region/billing-region.service';

@Component({
  selector: 'insig-booking-time-alert',
  templateUrl: './time-alert.component.html',
  styleUrls: ['./time-alert.component.scss'],
})
export class TimeAlertComponent implements OnChanges, OnDestroy {
  @HostBinding('attr.role') role = 'button';

  @Input() currentReservedAppointmentSlot: DraftAppointmentSlot | undefined;

  public expiryCountdown = '-:--'; // mm:ss

  private expiryCountdownTimeout: number;

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private appointmentReservationService: AppointmentReservationService,
    private bookingStepService: BookingStepService,
    private billingTypeService: BillingTypeService,
    private billingRegionService: BillingRegionService,
    private route: ActivatedRoute,
  ) {
    this.expiryCountdownTimeout = this.subscribeToExpiryCountdown();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentReservedAppointmentSlot.currentValue !== changes.currentReservedAppointmentSlot.previousValue) {
      this.unsubscribeFromExpiryCountdown();
      if (changes.currentReservedAppointmentSlot.currentValue !== undefined) {
        this.expiryCountdownTimeout = this.subscribeToExpiryCountdown();
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeFromExpiryCountdown();
  }

  async extendTime(): Promise<void> {
    if (this.currentReservedAppointmentSlot === undefined || this.currentReservedAppointmentSlot.canDraftAppointmentExpiryBeExtended === false) {
      return;
    }

    try {
      await this.appointmentReservationService.extendCurrentReservedAppointmentSlot();
      this.snackBar.open('Time slot expiry extended.', undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
    } catch (error) {
      if (error instanceof Error) {
        this.snackBar.open(error.message, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
      } else {
        console.error(error);
        this.snackBar.open('An error occurred while extending the time slot expiry.', undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
      }
    }
  }

  subscribeToExpiryCountdown(): number {
    return window.setInterval(() => {
      this.updateExpiryCountdown(this.currentReservedAppointmentSlot);
    }, MILLISECONDS_PER_SECOND);
  }

  unsubscribeFromExpiryCountdown(): void {
    window.clearTimeout(this.expiryCountdownTimeout);
  }

  updateExpiryCountdown(currentReservedAppointmentSlot: DraftAppointmentSlot | undefined): void {
    if (currentReservedAppointmentSlot !== undefined) {
      this.expiryCountdown = this.getExpiryCountdownValue(currentReservedAppointmentSlot.expiryTime);
      if (new Date().getTime() > currentReservedAppointmentSlot.expiryTime) {
        this.handleExpiredReservedAppointmentSlot(currentReservedAppointmentSlot);
      }
    }
  }

  handleExpiredReservedAppointmentSlot(currentReservedAppointmentSlot: DraftAppointmentSlot | undefined): void {
    if (currentReservedAppointmentSlot !== undefined) {
      this.snackBar.open('Your time slot has expired. Please select a new time slot', undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
      this.dialog.closeAll();
      this.appointmentReservationService.clearCurrentReservedAppointmentSlot();
      const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [CompanyBookingComponent]);
      const region = this.billingRegionService.getBillingRegion(currentReservedAppointmentSlot.province, this.billingTypeService.parseBillingType(currentReservedAppointmentSlot.billingType));
      this.bookingStepService.jumpToStep(BookingStep.CHOOSE_TIME, {
        navigationExtras: {
          relativeTo: companyBookingRoute,
        },
        pathParams: {
          region,
          doctorId: currentReservedAppointmentSlot.doctorId,
          serviceId: currentReservedAppointmentSlot.serviceId,
        },
      });
      this.unsubscribeFromExpiryCountdown();
    }
  }

  getExpiryCountdownValue(expiryTime: number): string {
    const expiresInMs = expiryTime - new Date().getTime();
    let expiresInMinutesPlace = Math.floor(expiresInMs / MILLISECONDS_PER_MINUTE);
    let expiresInSecondsPlace = Math.floor((expiresInMs % MILLISECONDS_PER_MINUTE) / MILLISECONDS_PER_SECOND);

    if (expiresInMinutesPlace < 0) {
      expiresInMinutesPlace = 0;
    }

    if (expiresInSecondsPlace < 0) {
      expiresInSecondsPlace = 0;
    }

    const paddedSeconds = expiresInSecondsPlace < 10 ? `0${expiresInSecondsPlace}` : `${expiresInSecondsPlace}`;

    return `${expiresInMinutesPlace}:${paddedSeconds}`;
  }
}
