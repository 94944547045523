import { Component, Input, OnInit } from '@angular/core';
import { DAYS_PER_WEEK } from '@insig-health/services/date-and-time/date-and-time.constants';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';

@Component({
  selector: 'insig-booking-availability-label',
  templateUrl: './availability-label.component.html',
  styleUrls: ['./availability-label.component.scss'],
})
export class AvailabilityLabelComponent implements OnInit {
  @Input() date: Date | undefined;

  public availabilityString = '';
  public isAvailableToday = false;

  constructor(private dateAndTimeService: DateAndTimeService) { }

  ngOnInit(): void {
    this.availabilityString = this.getAvailabilityString(this.date);
    this.isAvailableToday = this.getIsAvailableToday(this.date);
  }

  getIsAvailableToday(date: Date | undefined): boolean {
    if (date === undefined || isNaN(date.getTime())) {
      return false;
    }

    return this.dateAndTimeService.getLocalCalendarDaysDifferenceBetweenTwoDates(date, new Date()) === 0;
  }

  getAvailabilityString(date: Date | undefined): string {
    if (date === undefined || isNaN(date.getTime())) {
      return 'Doctor is not available';
    }

    const timezone = this.dateAndTimeService.getLocalTimeZone();
    const daysFromNow = this.dateAndTimeService.getLocalCalendarDaysDifferenceBetweenTwoDates(date, new Date());
    if (daysFromNow === 0) {
      return 'Available Today';
    } else if (daysFromNow === 1) {
      return 'Available Tomorrow';
    } else if (daysFromNow > 1 && daysFromNow < DAYS_PER_WEEK) {
      const dayOfTheWeek = this.dateAndTimeService.getDayOfTheWeek(date, timezone);
      return `Available on ${dayOfTheWeek}`;
    } else {
      const readableCalendarDate = this.dateAndTimeService.getReadableCalendarDate(date, timezone);
      return `Available on ${readableCalendarDate}`;
    }
  }
}
