<button *ngIf="quickBookDoctorSearchData" (click)="handleQuickBookClicked()" class="w-100">
  <div class="d-flex p-2 my-2 align-items-center rounded font-size-md quickBookContainer highlight-border-quickbook">
    <img
      class="d-block me-2 w-50px h-50px"
      src="./assets/images/lightning.png"
      alt="lightning icon"
    />
    <div class="d-flex flex-column mw-75">
      <div class="fw-bold text-nowrap">Quick Book from {{ quickBookDoctorSearchData.earliestAvailableDate | dateToTimeString }} - {{ quickBookDoctorSearchData.earliestAvailableDate | addMinutesToDate: QuickBookComponent.LOOK_AHEAD_MINUTES | dateToTimeString}}</div>
      <div class="d-flex my-1">
        <div class="text-truncate">{{ quickBookDoctorAppointment?.type }}</div>
        <div *ngIf="billingType === BillingType.PRIVATE || (quickBookDoctorAppointment?.price !== undefined && quickBookDoctorAppointment?.isPublicServiceCharged)" class="text-light ms-1 px-1 rounded blueBackground">${{ quickBookDoctorAppointment?.price }}</div>
      </div>
      <div class="d-flex">
        <div class="text-truncate">{{ quickBookDoctorSearchData.doctorMetadata?.title }} {{ quickBookDoctorSearchData.doctorMetadata?.fullName }}</div>
        <div class="d-flex gap-1 ms-1">
          <img *ngIf="quickBookDoctorAppointment?.mediums?.includes('VIDEO')" class="w-20px h-20px text-light rounded blueBackground p-1" src="assets/img/ico-doc-video-white.svg" alt="video">
          <img *ngIf="quickBookDoctorAppointment?.mediums?.includes('PHONE')" class="w-20px h-20px text-light rounded blueBackground p-1" src="assets/img/ico-doc-phone-white.svg" alt="phone">
          <img *ngIf="quickBookDoctorAppointment?.mediums?.includes('MESSAGING')" class="w-20px h-20px text-light rounded blueBackground p-1" src="assets/img/ico-doc-message-white.svg" alt="message">
        </div>
      </div>
    </div>
    <img class="arrowIcon ms-auto" src="assets/img/ico-forward-arrow.svg" alt="forward arrow">
  </div>
</button>
