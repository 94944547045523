import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyBookingComponent } from '../company-booking/company-booking.component';
import { AppointmentBooked } from '@insig-health/api/doctor-booking-flow-api-v1';
import { StripeService } from '../../services/stripe/stripe.service';
import { AppointmentReservationService, PaymentDetailsWithStripe } from '../../services/appointment-reservation/appointment-reservation.service';
import { BookingStep, BookingStepService } from '../../services/booking-step/booking-step.service';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/angular-material.config';

@Component({
  selector: 'insig-booking-stripe-payment-redirect',
  templateUrl: './stripe-payment-redirect.component.html',
  styleUrls: ['./stripe-payment-redirect.component.scss'],
})
export class StripePaymentRedirectComponent implements OnInit {
  public doctorId: string | undefined;
  public companyId: string | undefined;
  public appointmentMedium: string | undefined;
  public appointmentId: string | undefined;
  public serviceId: string | undefined;
  public startTime: string | undefined;
  public serviceType: string | undefined;

  constructor(
    private appointmentReservationService: AppointmentReservationService,
    private route: ActivatedRoute,
    private stripeService: StripeService,
    private router: Router,
    private bookingStepService: BookingStepService,
    private snackBar: MatSnackBar,
  ) {}

  async ngOnInit(): Promise<void> {
    // eslint-disable-next-line camelcase
    const { doctorId, appointmentMedium, appointmentId, serviceId, startTime, serviceType, payment_intent_client_secret, stripePaymentDetails } = this.route.snapshot.queryParams;

    this.doctorId = doctorId;
    this.appointmentMedium = appointmentMedium;
    this.appointmentId = appointmentId;
    this.serviceId = serviceId;
    this.startTime = startTime;
    this.serviceType = serviceType;

    this.companyId = this.route.snapshot.params.companyId;

    let parsedStripePaymentDetails: PaymentDetailsWithStripe | undefined;

    if (stripePaymentDetails) {
      try {
        parsedStripePaymentDetails = JSON.parse(stripePaymentDetails);
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    // eslint-disable-next-line camelcase
    const stripePaymentIntentClientSecret: string = payment_intent_client_secret;

    if (stripePaymentIntentClientSecret && this.appointmentId && parsedStripePaymentDetails) {
      await this.handlePaymentStatus(stripePaymentIntentClientSecret, this.appointmentId, parsedStripePaymentDetails);
    } else {
      this.emitErrorMessageForPayment();

      this.navigateToConfirmBookingPage();
    }
  }

  async handlePaymentStatus(stripePaymentIntentClientSecret: string, appointmentId: string, stripePaymentDetails: PaymentDetailsWithStripe): Promise<void> {
    try {
      const paymentStatus = await this.stripeService.getStripePaymentStatus(stripePaymentIntentClientSecret);

      if (paymentStatus === true) {
        const appointmentBooking = await this.appointmentReservationService.confirmReservedAppointmentSlotWithStripe(appointmentId, stripePaymentDetails);

        this.appointmentReservationService.clearCurrentReservedAppointmentSlot();
        this.navigateToBookingSuccessPage(appointmentBooking);
      } else {
        this.emitErrorMessageForPayment();

        this.navigateToConfirmBookingPage();
      }
    } catch (error) {
      let errorMessage = undefined;
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      this.emitErrorMessageForPayment(errorMessage);

      this.navigateToConfirmBookingPage();
    }
  }

  emitErrorMessageForPayment(errorMessage?: string): void {
    this.snackBar.open(
      (errorMessage) ? errorMessage : 'An error occurred with the payment.',
      undefined,
      {
        duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS,
      },
    );
  }

  navigateToConfirmBookingPage(): void {
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [CompanyBookingComponent]);
    this.bookingStepService.jumpToStep(BookingStep.CONFIRM_BOOKING, {
      navigationExtras: {
        relativeTo: companyBookingRoute,
      },
      pathParams: { draftAppointmentId: this.appointmentId ?? '' },
    });
  }

  navigateToBookingSuccessPage(appointmentBooking: AppointmentBooked): void {
    const successPageParameters = {
      queryParams: {
        doctorId: this.doctorId,
        appointmentMedium: this.appointmentMedium,
        startTime: this.startTime,
        serviceId: this.serviceId,
        serviceType: this.serviceType,
        surveyUrl: appointmentBooking.surveyUrl,
      },
    };

    this.router.navigate(['/success', this.companyId], successPageParameters);
  }
}
