import { Injectable } from '@angular/core';
import { AppointmentDraftResponse, AppointmentServiceMedium } from '@insig-health/api/doctor-booking-flow-api-v1';
import { DoctorSearchData } from '@insig-health/services/doctor/doctor.service';

@Injectable({
  providedIn: 'root',
})
export class QuickConnectService {
  async getQuickConnectDoctor(_companyId: string): Promise<DoctorSearchData> {
    // Mock data
    return {
      doctorMetadata: {
        id: 'mockId',
        companyId: 'mockCompanyId',
        fullName: this._getRandomName(),
        specialty: 'Dermatologist',
        languagesSpoken: [
          'EN',
          'ES',
        ],
        credentials: 'M.B.B.S., B.D.S., M.D., M.S.',
        profilePictureUrl: 'assets/img/img-docDisplay.png',
        patientRating: 0,
        onTimeRating: 0,
      },
      availabilityData: {
        weekAvailability: undefined,
      },
      service: {
        bookingRestrictions: null,
        mediums: this._getRandomMediums(),
        appointments: [],
      },
      earliestAvailableDate: new Date(),
    };
  }

  _getRandomName(): string {
    const names = [
      'Dr. John Doe',
      'Dr. Foo Bar',
      'Dr. Michel Daniloski',
      'Dr. Ozze',
      'Dr. Otto Octavius',
    ];

    return names[Math.floor(Math.random() * names.length)];
  }

  _getRandomMediums(): AppointmentServiceMedium[] {
    const allMediums = [AppointmentServiceMedium.Video, AppointmentServiceMedium.Phone, AppointmentServiceMedium.Messaging];
    const randomMediums = allMediums.filter(() => Math.random() >= 0.5);
    if (randomMediums.length !== 0) {
      return randomMediums;
    } else {
      return [allMediums[Math.floor(Math.random() * allMediums.length)]];
    }
  }

  async bookQuickConnectAppointment(): Promise<AppointmentDraftResponse> {
    return {
      appointmentDraftId: 'mockAppointmentDraftId',
    };
  }
}
