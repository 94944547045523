import { Component, Input, OnInit } from '@angular/core';
import { DoctorSearchData, DoctorService } from '@insig-health/services/doctor/doctor.service';

@Component({
  selector: 'insig-booking-appointment-summary',
  templateUrl: './appointment-summary.component.html',
})
export class AppointmentSummaryComponent implements OnInit {
  @Input() doctorId: string | undefined;
  @Input() serviceId: string | undefined;

  public doctorSearchData: DoctorSearchData | undefined;
  public serviceType = '';

  constructor(private doctorService: DoctorService) {}

  async ngOnInit(): Promise<void> {
    if (this.doctorId === undefined) {
      throw new Error('No doctorId provided');
    }

    if (this.serviceId === undefined) {
      throw new Error('No serviceId provided');
    }

    this.doctorSearchData = await this.doctorService.getDoctorById(this.doctorId);
    const appointment = this.doctorService.getAppointmentFromListById(this.doctorSearchData.service.appointments, this.serviceId);
    this.serviceType = appointment.type;
  }

  getDoctorTitleAndName(doctor: DoctorSearchData): string {
    if (doctor.doctorMetadata.title) {
      return `${doctor.doctorMetadata.title} ${doctor.doctorMetadata.fullName}`;
    } else {
      return `${doctor.doctorMetadata.fullName}`;
    }
  }
}
