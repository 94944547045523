import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';
import { Appointment, DoctorSearchData } from '@insig-health/services/doctor/doctor.service';
import { BillingType } from 'apps/insig-booking/src/services/billing-type/billing-type.service';
@Component({
  selector: 'insig-booking-quick-book',
  templateUrl: './quick-book.component.html',
  styleUrls: ['./quick-book.component.scss'],
})
export class QuickBookComponent implements OnChanges {
  static readonly LOOK_AHEAD_MINUTES = 30;

  @Input() doctorSearchData: DoctorSearchData[] = [];
  @Input() selectedAppointmentType: string | undefined;
  @Input() companyId: string | undefined;
  @Input() billingType: BillingType | undefined;

  @Output() quickBookClicked = new EventEmitter();

  public BillingType = BillingType;
  public QuickBookComponent = QuickBookComponent;
  public quickBookDoctorSearchData: DoctorSearchData | undefined;
  public quickBookDoctorAppointment: Appointment | undefined;

  constructor(
    private dateAndTimeService: DateAndTimeService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.doctorSearchData !== undefined) {
      this.handleDoctorSearchDataChange(changes.doctorSearchData);
    }
  }

  handleQuickBookClicked(): void {
    this.quickBookClicked.emit();
  }

  handleDoctorSearchDataChange(change: SimpleChange): void {
    if (change.currentValue !== change.previousValue && change.currentValue !== undefined) {
      if (this.companyId === 'tiaHealth') {
        this.quickBookDoctorSearchData = this.getTiaHealthQuickBookResult(change.currentValue, this.selectedAppointmentType);
      } else if (this.selectedAppointmentType) {
        this.quickBookDoctorSearchData = this.getQuickBookResult(change.currentValue, this.selectedAppointmentType);
      } else {
        this.quickBookDoctorSearchData = undefined;
      }

      if (this.quickBookDoctorSearchData) {
        this.quickBookDoctorAppointment = this.getQuickBookDoctorAppointment(this.quickBookDoctorSearchData, this.selectedAppointmentType, this.companyId);
      }
    }
  }

  getQuickBookResult(doctorSearchData: DoctorSearchData[], selectedAppointmentType: string): DoctorSearchData | undefined {
    const doctorsWithOptionalService = doctorSearchData.map((doctorSearchData) => {
      const service = doctorSearchData.service.appointments.find((appointment) => appointment.type === selectedAppointmentType);
      return { doctorSearchData, service };
    });

    const doctorsWithService = doctorsWithOptionalService.filter((doctorAndService): doctorAndService is {
      doctorSearchData: DoctorSearchData,
      service: Appointment
    } => {
      return doctorAndService.service !== undefined;
    });

    const doctorsWithOptionalServiceAvailability = doctorsWithService.map(({ doctorSearchData, service }) => {
      return {
        doctorSearchData,
        earliestAvailabilityForAppointment: service.earliestAvailabilityForAppointment,
      };
    });

    const doctorsWithServiceAvailability = doctorsWithOptionalServiceAvailability.filter((doctorAndAvailability): doctorAndAvailability is {
      doctorSearchData: DoctorSearchData,
      earliestAvailabilityForAppointment: Date
    } => {
      return doctorAndAvailability.earliestAvailabilityForAppointment !== undefined && this.dateAndTimeService.isSameCalendarDate(doctorAndAvailability.earliestAvailabilityForAppointment, new Date());
    });

    const doctorsSortedByEarliestAvailability = doctorsWithServiceAvailability.sort((doctorAndAvailabilityA, doctorAndAvailabilityB) => {
      const earliestAvailabilityA = doctorAndAvailabilityA.earliestAvailabilityForAppointment;
      const earlisetAvailabilityB = doctorAndAvailabilityB.earliestAvailabilityForAppointment;
      return earliestAvailabilityA.getTime() - earlisetAvailabilityB.getTime();
    });

    return doctorsSortedByEarliestAvailability[0]?.doctorSearchData;
  }

  getDoctorEarliestAvailabilityForAppointmentType(doctor: DoctorSearchData, appointmentType: string): Date | undefined {
    const service = doctor.service.appointments.find((appointment) => appointment.type === appointmentType);
    return service?.earliestAvailabilityForAppointment;
  }

  getTiaHealthQuickBookResult(doctorSearchData: DoctorSearchData[], selectedAppointmentType: string | undefined): DoctorSearchData | undefined {
    if (selectedAppointmentType) {
      return this.getQuickBookResult(doctorSearchData, selectedAppointmentType);
    } else {
      const generalAppointmentDoctors = this.getDoctorsWithServiceType(doctorSearchData, '* General Appointment');
      return this.getQuickBookResult(generalAppointmentDoctors, '* General Appointment');
    }
  }

  getDoctorsWithServiceType(doctorSearchData: DoctorSearchData[], serviceType: string): DoctorSearchData[] {
    return doctorSearchData.filter((doctor) => doctor.service.appointments.some((service) => service.type === serviceType));
  }

  getQuickBookDoctorAppointment(doctor: DoctorSearchData, appointmentType: string | undefined, companyId: string | undefined): Appointment | undefined {
    if (appointmentType) {
      return doctor.service.appointments.find((appointment) => appointment.type === appointmentType);
    } else if (companyId === 'tiaHealth') {
      return doctor.service.appointments.find((appointment) => appointment.type === '* General Appointment');
    } else {
      return undefined;
    }
  }
}
