<ng-container *ngTemplateOutlet="mobileView"></ng-container>
<ng-container *ngTemplateOutlet="desktopView"></ng-container>

<ng-template #mobileView>
  <insig-ui-card class="d-block d-md-none mb-2">
    <div class="h-100 d-flex flex-column justify-content-between">
      <insig-booking-pharmacy-stand-alone-form></insig-booking-pharmacy-stand-alone-form>

      <div>
        <div class="d-flex align-items-center mt-0 tiaDelivery">
          <img src="./assets/images/tiahealth-logo.png" class="max-width-30" alt="Pharmacy Logo"/>
          <div class="font-size-lg bold textBlue ps-2">Prescription Delivery</div>
        </div>
        <div class="py-1"><!-- spacer --></div>
        <div>
          <span class="bold textSecondary">
            All your medications delivered directly to your door, arriving same day or next business day.
          </span>
          <span role="button" class="bold textBlue" (click)="handleFulfilledByClicked()">
            Fulfilled by <span class="text-decoration-underline">{{ prescriptionDeliveryPharmacyName }}</span>
          </span>
        </div>
        <div class="py-1"><!-- spacer --></div>
        <div>
          <label
            role="button"
            class="d-flex align-items-center p-2 rounded w-60"
            [ngClass]="{
              'greenBorder': getFreePrescriptionDeliverySelected,
              'grayBorder': !getFreePrescriptionDeliverySelected
            }"
          >
            <img [src]="getFreePrescriptionDeliverySelected ? 'assets/img/ico-checked-outlined.svg' : 'assets/img/ico-unchecked.svg'" class="px-2" alt="" />
            <div class="bold textSecondary">Get Free Prescription Delivery</div>
            <input
              hidden
              class="ms-auto"
              type="radio"
              name="prescriptionDeliveryMobile"
              [checked]="getFreePrescriptionDeliverySelected"
              (click)="handleGetFreePrescriptionDeliveryClicked(getFreePrescriptionDeliverySelected)"
            >
          </label>
        </div>
      </div>
    </div>
  </insig-ui-card>
</ng-template>
<ng-template #desktopView>
  <insig-ui-card class="h-100 d-none d-md-flex flex-column maxWidth325Px">
    <div class="h-100 d-flex flex-column justify-content-between">
      <insig-booking-pharmacy-stand-alone-form></insig-booking-pharmacy-stand-alone-form>

      <div>
        <div class="d-flex align-items-center mt-0 tiaDelivery">
          <img src="./assets/images/tiahealth-logo.png" class="max-width-30" alt="Pharmacy Logo"/>
          <div class="font-size-md bold textBlue ps-2">Prescription Delivery</div>
        </div>
        <div class="py-2"><!-- spacer --></div>
        <div class="font-size-md textSecondary">All your medications delivered directly to your door, arriving same day or next business day.</div>
        <div class="py-4"><!-- spacer --></div>
        <div role="button" class="bold textBlue" (click)="handleFulfilledByClicked()">Fulfilled by <span class="text-decoration-underline">{{ prescriptionDeliveryPharmacyName }}</span></div>
        <div class="mt-4">
          <label
            role="button"
            class="d-flex align-items-center p-2 rounded w-60"
            [ngClass]="{
              'greenBorder': getFreePrescriptionDeliverySelected,
              'grayBorder': !getFreePrescriptionDeliverySelected
            }"
          >
            <img [src]="getFreePrescriptionDeliverySelected ? 'assets/img/ico-checked-outlined.svg' : 'assets/img/ico-unchecked.svg'" class="px-2" alt="" />
            <div class="bold textSecondary">Get Free Prescription Delivery</div>
            <input
              hidden
              class="ms-auto"
              type="radio"
              name="prescriptionDeliveryDesktop"
              [checked]="getFreePrescriptionDeliverySelected"
              (click)="handleGetFreePrescriptionDeliveryClicked(getFreePrescriptionDeliverySelected)"
            >
          </label>
        </div>
      </div>
    </div>
  </insig-ui-card>
</ng-template>
