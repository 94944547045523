import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BillingTypeBookingComponent } from '../../../billing-type-booking/billing-type-booking.component';
import { CompanyBookingComponent } from '../../../company-booking/company-booking.component';
import { ProvinceBookingComponent } from '../../..//province-booking/province-booking.component';
import { BookingStep, BookingStepService } from 'apps/insig-booking/src/services/booking-step/booking-step.service';
import { BillingRegionService } from 'apps/insig-booking/src/services/billing-region/billing-region.service';

@Component({
  selector: 'insig-booking-selected-appointment-options',
  templateUrl: './selected-appointment-options.component.html',
  styleUrls: ['./selected-appointment-options.component.scss'],
})
export class SelectedAppointmentOptionsComponent {

  constructor(
    private bookingStepService: BookingStepService,
    private billingRegionService: BillingRegionService,
    private activatedRoute: ActivatedRoute,
  ) { }

  @Input() serviceType: string | undefined;

  async handleEditButtonClicked(): Promise<void> {
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.activatedRoute, [CompanyBookingComponent]);
    const provinceBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.activatedRoute, [ProvinceBookingComponent]);
    const provinceAbbreviation = provinceBookingRoute.snapshot.params.provinceAbbreviation;
    const billingTypeRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.activatedRoute, [BillingTypeBookingComponent]);
    const billingType = billingTypeRoute.snapshot.params.billingType;
    const region = this.billingRegionService.getBillingRegion(provinceAbbreviation, billingType);
    await this.bookingStepService.jumpToStep(BookingStep.CHOOSE_DOCTOR, {
      navigationExtras: { relativeTo: companyBookingRoute },
      pathParams: { region },
    });
  }

}
