import { Component, Inject } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'insig-booking-delete-patient-warning-dialog',
  templateUrl: './delete-patient-warning-dialog.component.html',
})
export class DeletePatientWarningDialogComponent {

  static readonly DIALOG_MAX_WIDTH = '480px';
  static readonly DIALOG_WIDTH = '100vw';

  static readonly DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
    width: DeletePatientWarningDialogComponent.DIALOG_WIDTH,
    maxWidth:  DeletePatientWarningDialogComponent.DIALOG_MAX_WIDTH,
  };

  constructor(
      @Inject(MAT_DIALOG_DATA) public data = { fullName: '' },
      public dialogRef: MatDialogRef<DeletePatientWarningDialogComponent>,
  ) {}
}
