import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DoctorService, Appointment, ExtendedSlotData } from '@insig-health/services/doctor/doctor.service';
import { BookingStepService, BookingStep, JumpToStepOptions } from '../../services/booking-step/booking-step.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { CompanyBookingComponent } from '../company-booking/company-booking.component';
import { DoctorBookingComponent } from '../doctor-booking/doctor-booking.component';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';
import { DateChangeDialogComponent } from './doc-details/date-change-dialog/date-change-dialog.component';
import { SelectedTimeSlotService } from '../../services/selected-time-slot/selected-time-slot.service';
import { filter } from 'rxjs/operators';
import { AppointmentReservationService, DraftOrigin } from '../../services/appointment-reservation/appointment-reservation.service';
import { AppointmentMedium, AppointmentMediumService } from '../../services/appointment-medium/appointment-medium.service';
import { ProvinceBookingComponent } from '../province-booking/province-booking.component';
import { InternationalBookingComponent } from '../international-booking/international-booking.component';
import { MILLISECONDS_PER_DAY } from '@insig-health/services/date-and-time/date-and-time.constants';
import { Province, ProvinceService } from '@insig-health/services/province/province.service';
import { BillingType, BillingTypeService } from '../../services/billing-type/billing-type.service';
import { BillingTypeBookingComponent } from '../billing-type-booking/billing-type-booking.component';
import { firstValueFrom, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { INSIG_BOOKING_SITE, SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/config';
import { DoctorSearchService } from '../../services/doctor-search/doctor-search.service';
import { AuthService } from '@insig-health/services/auth/auth.service';
import { BillingRegionService } from '../../services/billing-region/billing-region.service';
import { HttpErrorResponse } from '@angular/common/http';

export interface ChooseTimeQueryParams {
  serviceCategory: string,
  serviceId: string,
  serviceType: string,
  appointmentMedium?: string,
}

@Component({
  selector: 'insig-booking-choose-time',
  templateUrl: './choose-time.component.html',
})
export class ChooseTimeComponent implements OnInit, OnDestroy {
  static readonly DAYS_AHEAD_TO_SEARCH = 7;
  public static readonly TIME_SLOT_SELECTION_ERROR_MESSAGE = 'Sorry, this time slot is no longer available. Please try selecting a different time.';
  public static readonly GENERIC_ERROR_MESSAGE = 'An error occurred. Please try again later.';

  public doctorId: string;
  public companyId: string;
  public serviceId: string;
  public initialStartDate: Date | undefined;
  public currentStartDate: Date = new Date();
  public selectedMedium: AppointmentMedium | undefined;

  public appointmentMedium: string | undefined;
  public availableAppointmentMediums: AppointmentMedium[] = [];

  public appointment = {} as Appointment;

  public loading = true;
  public province: Province;
  public billingType: BillingType;

  private selectedTimeSlotSubscription: Subscription | undefined;

  constructor(
    private authService: AuthService,
    private doctorService: DoctorService,
    private doctorSearchService: DoctorSearchService,
    private bookingStepService: BookingStepService,
    private billingRegionService: BillingRegionService,
    private dateAndTimeService: DateAndTimeService,
    private selectedTimeSlotService: SelectedTimeSlotService,
    private appointmentMediumService: AppointmentMediumService,
    private appointmentReservationService: AppointmentReservationService,
    private provinceService: ProvinceService,
    private billingTypeService: BillingTypeService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
    try {
      const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [CompanyBookingComponent]);
      const provinceBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [ProvinceBookingComponent, InternationalBookingComponent]);
      const billingTypeBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [BillingTypeBookingComponent]);
      const doctorBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [DoctorBookingComponent]);

      this.companyId = companyBookingRoute.snapshot.params.companyId;
      this.doctorId = doctorBookingRoute.snapshot.params.doctorId;
      try {
        this.province = this.provinceService.parseQueryParamProvince(provinceBookingRoute.snapshot.params.provinceAbbreviation);
      } catch (error) {
        this.province = Province.ON;
      }

      this.billingType = this.billingTypeService.parseBillingType(billingTypeBookingRoute.snapshot.params.billingType);
    } catch (error) {
      this.openErrorDialog();
      throw error;
    }

    this.serviceId = this.route.snapshot.params.serviceId;
  }

  async ngOnInit(): Promise<void> {
    if (this.selectedTimeSlotService.getSelectedTimeSlot() !== undefined) {
      this.selectedTimeSlotService.clearSelectedTimeSlot();
    }

    try {
      const doctorFacetSearchData = await this.doctorSearchService.getDoctorAndFacetData(this.companyId, {
        doctorId: this.doctorId,
        province: this.province,
        billingType: this.billingType,
        billingNumber: undefined,
      });
      const doctorSearchData = doctorFacetSearchData.doctorSearchData[0];
      if (doctorSearchData === undefined) {
        this.navigateToChooseDoctorPage();
        return;
      }

      this.appointment = this.doctorService.getAppointmentFromListById(doctorSearchData.service.appointments, this.serviceId);

      this.availableAppointmentMediums = this.appointmentMediumService.parseAppointmentMediums(this.appointment.mediums);

      this.selectedTimeSlotSubscription = this.selectedTimeSlotService.getSelectedTimeSlotObservable()
        .pipe(filter((timeSlot): timeSlot is ExtendedSlotData => timeSlot !== undefined))
        .subscribe((timeSlot) => {
          this.handleTimeSlotSelected(timeSlot);
        });

      const startTimeEpoch = this.appointment.earliestAvailabilityForAppointment?.getTime() ?? new Date().getTime();
      const endTimeEpoch = startTimeEpoch + ChooseTimeComponent.DAYS_AHEAD_TO_SEARCH * MILLISECONDS_PER_DAY;
      this.initialStartDate = await this.getInitialStartDate(this.doctorId, this.appointment.duration, startTimeEpoch, endTimeEpoch);

      if (!this.initialStartDate) {
        this.navigateToChooseDoctorPage();
      } else {
        this.currentStartDate = this.initialStartDate;
        const timezone = this.dateAndTimeService.getLocalTimeZone();
        if (!this.dateAndTimeService.areTwoDatesTheSameDay(this.initialStartDate, new Date(), timezone)) {
          this.dialog.open(DateChangeDialogComponent, {
            data: {
              startTime: this.initialStartDate.getTime(),
            },
          });
        }
      }
    } catch (error) {
      console.error(error);
      await this.openErrorDialog(error);
      throw error;
    }

    this.loading = false;
  }

  ngOnDestroy(): void {
    this.selectedTimeSlotSubscription?.unsubscribe();
  }

  async getInitialStartDate(doctorId: string, slotDuration: number, startTimeEpoch: number, endTimeEpoch: number): Promise<Date | undefined> {
    const timeZone = 'utc';
    const startDateIsoString = this.dateAndTimeService.getDateAsYearMonthDay(new Date(startTimeEpoch), timeZone);
    const endDateIsoString = this.dateAndTimeService.getDateAsYearMonthDay(new Date(endTimeEpoch), timeZone);
    const slots = await this.doctorService.getDoctorSlotData(startDateIsoString, endDateIsoString, slotDuration, doctorId);

    const currentTimeEpoch = new Date().getTime();
    const futureSlots = slots
      .filter((slot) => slot.startDate.getTime() > currentTimeEpoch)
      .sort((slotA, slotB) => slotA.startDate.getTime() - slotB.startDate.getTime());
    return futureSlots[0]?.startDate;
  }

  async handleTimeSlotSelected(timeSlot: ExtendedSlotData): Promise<void> {
    this.loading = true;

    try {
      const draftAppointment = await this.appointmentReservationService.reserveAppointmentSlot({
        province: this.province,
        doctorId: timeSlot.doctorId,
        serviceId: this.serviceId,
        serviceMedium: this.selectedMedium ?? AppointmentMedium.VIDEO,
        locationId: timeSlot.locationId ?? 'virtual',
        startTime: timeSlot.startDate.getTime(),
        billingType: this.billingType,
        lookAheadMinutes: 0,
        draftOrigin: DraftOrigin.PATIENT_SELECTED_TIME,
        bookingSiteUrl: window.location.href,
        bookingSite: INSIG_BOOKING_SITE,
      });
      const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [CompanyBookingComponent]);

      const isLoggedIn = await firstValueFrom(this.authService.isLoggedIntoSpring());
      if (isLoggedIn) {
        this.bookingStepService.jumpToStep(BookingStep.CONFIRM_BOOKING, {
          navigationExtras: {
            relativeTo: companyBookingRoute,
          },
          pathParams: {
            draftAppointmentId: draftAppointment.appointmentId,
          },
        });
      } else {
        this.bookingStepService.jumpToStep(BookingStep.LOGIN, {
          navigationExtras: {
            relativeTo: companyBookingRoute,
          },
          pathParams: {
            draftAppointmentId: draftAppointment.appointmentId,
          },
        });
      }
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        this.snackBar.open(error.message, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
      } else if (error instanceof HttpErrorResponse) {
        if (error.status === 404 || error.status === 409) {
          this.snackBar.open(ChooseTimeComponent.TIME_SLOT_SELECTION_ERROR_MESSAGE, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
        } else {
          this.snackBar.open(error.error?.errorMessage ?? ChooseTimeComponent.GENERIC_ERROR_MESSAGE, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
        }
      } else {
        this.snackBar.open(ChooseTimeComponent.GENERIC_ERROR_MESSAGE, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
      }
      this.selectedTimeSlotService.clearSelectedTimeSlot();
    }

    this.loading = false;
  }

  navigateToChooseDoctorPage(): void {
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [CompanyBookingComponent]);
    const provinceBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [ProvinceBookingComponent, InternationalBookingComponent]);
    const provinceAbbreviation = provinceBookingRoute.snapshot.params.provinceAbbreviation;
    const billingTypeBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [BillingTypeBookingComponent]);
    const billingType = this.billingTypeService.parseBillingType(billingTypeBookingRoute.snapshot.params.billingType);
    const region = this.billingRegionService.getBillingRegion(provinceAbbreviation, billingType);
    const navigationExtras = { relativeTo: companyBookingRoute } as JumpToStepOptions;
    this.bookingStepService.jumpToStep(BookingStep.CHOOSE_DOCTOR, {
      navigationExtras,
      pathParams: { region },
    });
  }

  async openErrorDialog(error?: unknown): Promise<void> {
    const dialogRef = this.dialog.open(ErrorDialogComponent, { data: error });

    await firstValueFrom(dialogRef.afterClosed());

    this.navigateToChooseDoctorPage();
  }
}
