import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FamilyMemberProfile } from '@insig-health/services/family-member/family-member.service';
import { JAVA_BACKEND_ENDPOINT } from '@insig-health/config/config';
import { PatientProfile } from '@insig-health/services/patient-profile/patient-profile.service';
import { Province } from '@insig-health/services/province/province.service';
import { firstValueFrom } from 'rxjs';
import { DraftAppointmentSlot, DraftErrorCode } from '../appointment-reservation/appointment-reservation.service';

type HealthCardProvince = Province;
const HealthCardProvinceEnum = Province;

export interface HealthCard {
  number: string;
  region: HealthCardProvince;
}

@Injectable({
  providedIn: 'root',
})
export class HealthCardService {
  constructor(
    private http: HttpClient,
  ) { }

  // TODO: implement once healthcard validation endpoint is created
  async isHealthCardValid(healthCard: HealthCard): Promise<boolean> {
    return healthCard.number !== '';
  }

  isHealthCardValidInDraft(draftAppointment: DraftAppointmentSlot): boolean {
    return draftAppointment.errorCodes.includes(DraftErrorCode.INVALID_HEALTHCARD);
  }

  async setHealthCard(_patientUid: string, _healthCard: HealthCard): Promise<void> {
    // To be implemented
  }

  async getHealthCard(patientUid: string): Promise<HealthCard> {
    const patientProfile = await firstValueFrom(this.http.get(`${JAVA_BACKEND_ENDPOINT}patient/${patientUid}`)) as PatientProfile;
    return this.getHealthCardFromProfile(patientProfile);
  }

  getHealthCardFromProfile(profile: PatientProfile | FamilyMemberProfile): HealthCard {
    return {
      number: profile.healthCardNumber,
      region: this.parseHealthCardProvince(profile.province),
    };
  }

  getNewHealthCard(healthCardNumber: string, healthCardRegion: Province): HealthCard {
    return {
      number: healthCardNumber,
      region: healthCardRegion,
    };
  }

  parseHealthCardProvince(provinceString: string): HealthCardProvince {
    provinceString = provinceString.toUpperCase();
    switch (provinceString) {
      case 'BRITISH COLUMBIA': {
        return HealthCardProvinceEnum.BC;
      }
      case 'BC': {
        return HealthCardProvinceEnum.BC;
      }
      case 'ALBERTA': {
        return HealthCardProvinceEnum.AB;
      }
      case 'AB': {
        return HealthCardProvinceEnum.AB;
      }
      case 'SASKATCHEWAN': {
        return HealthCardProvinceEnum.SK;
      }
      case 'SK': {
        return HealthCardProvinceEnum.SK;
      }
      case 'MANITOBA': {
        return HealthCardProvinceEnum.MB;
      }
      case 'MB': {
        return HealthCardProvinceEnum.MB;
      }
      case 'ONTARIO': {
        return HealthCardProvinceEnum.ON;
      }
      case 'ON': {
        return HealthCardProvinceEnum.ON;
      }
      case 'QUEBEC': {
        return HealthCardProvinceEnum.QC;
      }
      case 'QC': {
        return HealthCardProvinceEnum.QC;
      }
      case 'NEWFOUNDLAND AND LABRADOR': {
        return HealthCardProvinceEnum.NL;
      }
      case 'NL': {
        return HealthCardProvinceEnum.NL;
      }
      case 'NOVA SCOTIA': {
        return HealthCardProvinceEnum.NS;
      }
      case 'NS': {
        return HealthCardProvinceEnum.NS;
      }
      case 'NEW BRUNSWICK': {
        return HealthCardProvinceEnum.NB;
      }
      case 'NB': {
        return HealthCardProvinceEnum.NB;
      }
      case 'PRINCE EDWARD ISLAND': {
        return HealthCardProvinceEnum.PE;
      }
      case 'PE': {
        return HealthCardProvinceEnum.PE;
      }
      case 'YUKON': {
        return HealthCardProvinceEnum.YT;
      }
      case 'YT': {
        return HealthCardProvinceEnum.YT;
      }
      case 'NORTHWEST TERRITORIES': {
        return HealthCardProvinceEnum.NT;
      }
      case 'NT': {
        return HealthCardProvinceEnum.NT;
      }
      case 'NUNAVUT': {
        return HealthCardProvinceEnum.NU;
      }
      case 'NU': {
        return HealthCardProvinceEnum.NU;
      }
      default: {
        return HealthCardProvinceEnum.ON; // Default to Ontario
      }
    }
  }

  getHealthCardProvinces(): HealthCardProvince[] {
    return Object.values(HealthCardProvinceEnum);
  }
}
