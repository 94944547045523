import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { DoctorService, ExtendedSlotData } from '@insig-health/services/doctor/doctor.service';
import { MILLISECONDS_PER_SECOND } from '@insig-health/services/date-and-time/date-and-time.constants';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';
import { BookingStepService, BookingStep } from 'apps/insig-booking/src/services/booking-step/booking-step.service';
import { ActivatedRoute } from '@angular/router';
import { BillingTypeService } from 'apps/insig-booking/src/services/billing-type/billing-type.service';
import { CompanyBookingComponent } from 'apps/insig-booking/src/app/company-booking/company-booking.component';
import { ProvinceBookingComponent } from 'apps/insig-booking/src/app/province-booking/province-booking.component';
import { BillingTypeBookingComponent } from 'apps/insig-booking/src/app/billing-type-booking/billing-type-booking.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/config';
import { BillingRegionService } from 'apps/insig-booking/src/services/billing-region/billing-region.service';

@Component({
  selector: 'insig-booking-appointment-slot-calendar',
  templateUrl: './appointment-slot-calendar.component.html',
  styleUrls: ['./appointment-slot-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentSlotCalendarComponent implements OnDestroy, OnChanges {
  public static readonly NO_AVAILABILITY_ERROR_MESSAGE = 'Sorry, this practitioner is no longer available. Please select a different practitioner.';

  @Input() slotDuration = 0;
  @Input() startDate = new Date();
  @Input() doctorId: string | undefined;

  public doctorSlotsByDate: { [dateYearMonthDayIsoString: string]: ExtendedSlotData[] | undefined } = {};

  private slotDataRefreshInterval: number | undefined = undefined;

  private DOCTOR_SLOTS_REFRESH_RATE = MILLISECONDS_PER_SECOND * 3;
  private NUMBER_OF_DAYS_ON_CALENDAR = 7;
  public DIFFERENCES_IN_DAYS = [...Array(this.NUMBER_OF_DAYS_ON_CALENDAR).keys()];

  public currentWeekStartDate: string | undefined;

  constructor(
    private billingTypeService: BillingTypeService,
    private bookingStepService: BookingStepService,
    private billingRegionService: BillingRegionService,
    private doctorService: DoctorService,
    public dateAndTimeService: DateAndTimeService,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
  ) { }

  ngOnDestroy(): void {
    window.clearInterval(this.slotDataRefreshInterval);
  }

  async ngOnChanges(simpleChanges: SimpleChanges): Promise<void> {
    const { slotDuration, startDate, doctorId } = simpleChanges;
    const currentValues = {
      slotDuration: slotDuration?.currentValue ?? this.slotDuration,
      startDate: startDate?.currentValue ?? this.startDate,
      doctorId: doctorId?.currentValue ?? this.doctorId,
    };

    const endDate = this.dateAndTimeService.addDaysToDate(currentValues.startDate, this.NUMBER_OF_DAYS_ON_CALENDAR);

    const timeZone = 'utc';
    const startDateIsoString = this.dateAndTimeService.getDateAsYearMonthDay(currentValues.startDate, timeZone);
    const endDateIsoString = this.dateAndTimeService.getDateAsYearMonthDay(endDate, timeZone);

    if (Object.values(currentValues).every((value) => value !== undefined)) {
      this.updateDoctorSlots(
        currentValues.doctorId,
        startDateIsoString,
        endDateIsoString,
        currentValues.slotDuration,
      );
    }

    this.currentWeekStartDate = startDateIsoString;
  }

  async updateDoctorSlots(doctorId: string, startDateIsoString: string, endDateIsoString: string, slotDuration: number): Promise<void> {

    window.clearInterval(this.slotDataRefreshInterval);

    this.slotDataRefreshInterval = window.setInterval(async () => {
      await this.handleAvailabilityUpdate(
        doctorId,
        startDateIsoString,
        endDateIsoString,
        slotDuration,
      );
      this.changeDetector.detectChanges();
    }, this.DOCTOR_SLOTS_REFRESH_RATE);

    const allDoctorSlots = await this.doctorService.getDoctorSlotData(startDateIsoString, endDateIsoString, slotDuration, doctorId);
    this.doctorSlotsByDate = this.getDoctorSlotsByDate(allDoctorSlots);

    this.changeDetector.detectChanges();
  }

  async handleAvailabilityUpdate(doctorId: string, startDateIsoString: string, endDateIsoString: string, slotDuration: number): Promise<void> {
    const allDoctorSlots = await this.doctorService.getDoctorSlotData(
      startDateIsoString,
      endDateIsoString,
      slotDuration,
      doctorId,
    );
    if (allDoctorSlots.length === 0) {
      const isDoctorAvailable = await this.doctorService.isDoctorAvailable(doctorId);
      if (!isDoctorAvailable) {
        this.snackBar.open(AppointmentSlotCalendarComponent.NO_AVAILABILITY_ERROR_MESSAGE, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
        this.navigateToChooseDoctorPage();
        return;
      }
    }
    this.doctorSlotsByDate = this.getDoctorSlotsByDate(allDoctorSlots);
  }

  getDoctorSlotsByDate(doctorSlots: ExtendedSlotData[]): { [dateYearMonthDayIsoString: string]: ExtendedSlotData[] } {
    return doctorSlots.reduce((slotsByDate, doctorSlot) => {
      const yearMonthDayIsoString = this.dateAndTimeService.getDateAsYearMonthDay(doctorSlot.startDate);

      if (slotsByDate[yearMonthDayIsoString] === undefined) {
        slotsByDate[yearMonthDayIsoString] = [];
      }

      slotsByDate[yearMonthDayIsoString].push(doctorSlot);
      return slotsByDate;
    }, {} as { [dateYearMonthDayIsoString: string]: ExtendedSlotData[] });
  }

  navigateToChooseDoctorPage(): void {
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [CompanyBookingComponent]);
    const provinceBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [ProvinceBookingComponent]);
    const provinceAbbreviation = provinceBookingRoute.snapshot.params.provinceAbbreviation;
    const billingTypeBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [BillingTypeBookingComponent]);
    const billingType = this.billingTypeService.parseBillingType(billingTypeBookingRoute.snapshot.params.billingType);
    const region = this.billingRegionService.getBillingRegion(provinceAbbreviation, billingType);
    this.bookingStepService.jumpToStep(BookingStep.CHOOSE_DOCTOR, {
      navigationExtras: {
        queryParams: {
          doctorId: undefined,
          appointmentType: undefined,
          appointmentCategory: undefined,
        },
        relativeTo: companyBookingRoute,
      },
      pathParams: { region },
    });
  }
}
