import { Injectable } from '@angular/core';
import {
  AppointmentBooked,
  AppointmentBookingService,
  AppointmentDraftRequest as ServerAppointmentDraftRequest,
  AppointmentDraftResponse,
  AppointmentDraftUpdateRequest as ServerAppointmentDraftUpdateRequest,
  BookingFlowVersion,
  BillingType,
  ErrorCode,
  DraftOrigin as ServerDraftOrigin,
  BookingSite,
} from '@insig-health/api/doctor-booking-flow-api-v1';
import { InsigBookingSite } from '@insig-health/config/config';
import { MILLISECONDS_PER_SECOND } from '@insig-health/services/date-and-time/date-and-time.constants';
import { Province, ProvinceService } from '@insig-health/services/province/province.service';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { AppointmentMedium, AppointmentMediumService } from '../appointment-medium/appointment-medium.service';
import { parseServiceMedium } from './appointment-reservation.service.utilities';

export interface AppointmentSlot {
  province: Province,
  startTime: number;
  companyId: string;
  doctorId: string;
  serviceId: string;
  serviceMedium: AppointmentMedium;
  availableServiceMediums: AppointmentMedium[];
  locationId: string;
  billingType: BillingType;
  price?: number;
  discountedPrice?: number;
}

export interface AppointmentDraftRequest {
  province: Province;
  serviceId: string;
  doctorId: string;
  serviceMedium: string;
  startTime: number;
  locationId: string;
  billingType?: BillingType;
  lookAheadMinutes: number;
  draftOrigin: DraftOrigin;
  bookingSiteUrl: string;
  bookingSite: InsigBookingSite;
}

export enum DraftOrigin {
  PATIENT_SELECTED_TIME = 'patient-selected-time',
  QUICK_BOOK = 'quick-book',
  OTHER = 'other',
}

export interface AppointmentDraftUpdateRequest {
  patientId?: string,
  familyMemberId?: string,
  serviceMedium?: string,
  discountCode?: string,
}

export type DraftAppointmentSlot = AppointmentSlot & {
  appointmentId: string;
  patientId?: string;
  familyMemberId?: string;
  expiryTime: number;
  canDraftAppointmentExpiryBeExtended?: boolean;
  stripePaymentIntentClientSecret: string;
  isServiceCharged: boolean;
  errorCodes: DraftErrorCode[];
  tooManyAppointmentsError: TooManyAppointmentsError;
}

export interface PaymentDetailsWithHealthCard {
  planId?: string;
  familyMemberId?: string;
  healthCardProvince: Province;
  healthCardNumber: string;
  bookingFlowVersion: BookingFlowVersion;
  timeZoneId: string;
}

export interface PaymentDetailsWithStripe {
  familyMemberId?: string;
  stripePaymentIntentId: string;
  bookingFlowVersion: BookingFlowVersion;
  timeZoneId: string;
}

export enum DraftErrorCode {
  DRAFT_ALREADY_EXISTS,
  AVAILABILITY_BLOCK_DOES_NOT_EXIST,
  DISCOUNT_CODE_ALREADY_USED,
  DISCOUNT_CODE_GET_RECORD,
  DISCOUNT_CODE_DOES_NOT_EXIST,
  FORBIDDEN,
  BAD_REQUEST,
  INTERNAL_SERVER_ERROR,
  DRAFT_APPOINTMENT_NOT_FOUND,
  CLINIC_CONSTRAINT_FAILED,
  INVALID_MEDIUM,
  MEDIUM_NOT_FOUND,
  RECORD_NOT_FOUND,
  BOOK_APPOINTMENT_TIMEOUT,
  BOOK_APPOINTMENT_ERROR,
  PAYMENT_CHARGE_FAILED,
  INVALID_HEALTHCARD,
  INVALID_SERVICE,
  DOCTOR_NOT_FOUND,
  DRAFT_INVALID_FOR_DOCTOR,
  PATIENT_NOT_FOUND,
  REQUIRED_FIELD_MISSING,
  INVALID_FIELD_ENTRY,
  OTHER_ERROR,
  TOO_MANY_APPOINTMENTS_IN_ONE_DAY,
  MAX_PUBLIC_BOOKINGS_REACHED,
  PATIENT_PROFILE_INCOMPLETE,
}

export interface TooManyAppointmentsError {
  tooManyAppointmentsInOneDay: boolean;
  familyMemberId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppointmentReservationService {
  public static readonly CANNOT_EXTEND_TIME_SLOT_EXPIRY_ERROR_MESSAGE = 'Cannot further extend the time slot expiry. You are too close to the appointment start time to extend your reservation';

  private _currentReservedAppointmentSlot = new BehaviorSubject<DraftAppointmentSlot | undefined>(undefined);

  constructor(
    private appointmentBookingService: AppointmentBookingService,
    private appointmentMediumService: AppointmentMediumService,
    private provinceService: ProvinceService,
  ) { }

  async reserveAppointmentSlot(appointmentSlot: AppointmentDraftRequest): Promise<DraftAppointmentSlot> {
    const appointmentDraftRequest: ServerAppointmentDraftRequest = {
      province: this.provinceService.convertToServerProvince(appointmentSlot.province),
      doctorId: appointmentSlot.doctorId,
      serviceId: appointmentSlot.serviceId,
      serviceMedium: appointmentSlot.serviceMedium,
      locationId: appointmentSlot.locationId,
      eventStart: new Date(appointmentSlot.startTime).toISOString(),
      billingType: appointmentSlot.billingType,
      lookAheadMinutes: appointmentSlot.lookAheadMinutes,
      draftOrigin: this.parseDraftOrigin(appointmentSlot.draftOrigin),
      bookingSiteUrl: appointmentSlot.bookingSiteUrl,
      bookingSite: this.getServerBookingSite(appointmentSlot.bookingSite),
    };

    const appointmentDraftResponse = await firstValueFrom(this.appointmentBookingService.createAppointmentDraft(appointmentDraftRequest));

    const draftAppointmentSlot = this.getDraftAppointmentSlotFromResponse(appointmentDraftResponse);

    this._currentReservedAppointmentSlot.next(draftAppointmentSlot);
    return draftAppointmentSlot;
  }

  parseDraftOrigin(draftOrigin: DraftOrigin): ServerDraftOrigin {
    switch (draftOrigin) {
      case DraftOrigin.PATIENT_SELECTED_TIME:
        return ServerDraftOrigin.PATIENT_SELECTED_TIME;

      case DraftOrigin.QUICK_BOOK:
        return ServerDraftOrigin.QUICKBOOK;

      case DraftOrigin.OTHER:
        return ServerDraftOrigin.OTHER;

      default:
        return ServerDraftOrigin.OTHER;
    }
  }

  async getReservedAppointmentSlot(appointmentId: string): Promise<DraftAppointmentSlot> {
    const appointmentDraftResponse = await firstValueFrom(this.appointmentBookingService.getAppointmentDraft(appointmentId));

    return this.getDraftAppointmentSlotFromResponse(appointmentDraftResponse);
  }

  async updateReservedAppointmentSlot(appointmentId: string, appointmentDraftUpdateRequest: AppointmentDraftUpdateRequest): Promise<DraftAppointmentSlot> {
    const serverAppointmentDraftUpdateRequest = this.getServerAppointmentDraftUpdateRequestFromAppointmentDraftUpdateRequest(appointmentDraftUpdateRequest);
    const updatedAppointmentDraftResponse = await firstValueFrom(this.appointmentBookingService.updateAppointmentDraft(appointmentId, serverAppointmentDraftUpdateRequest));
    const draftAppointmentSlot = this.getDraftAppointmentSlotFromResponse(updatedAppointmentDraftResponse);
    this._currentReservedAppointmentSlot.next(draftAppointmentSlot);
    return draftAppointmentSlot;
  }

  private getServerAppointmentDraftUpdateRequestFromAppointmentDraftUpdateRequest(appointmentDraftUpdateRequest: AppointmentDraftUpdateRequest): ServerAppointmentDraftUpdateRequest {
    return {
      patientId: appointmentDraftUpdateRequest.patientId,
      familyMemberId: appointmentDraftUpdateRequest.familyMemberId,
      serviceMedium: appointmentDraftUpdateRequest.serviceMedium,
      discountCode: appointmentDraftUpdateRequest.discountCode,
    };
  }

  async confirmReservedAppointmentSlotWithHealthCard(appointmentId: string, paymentDetails: PaymentDetailsWithHealthCard): Promise<AppointmentBooked> {
    return firstValueFrom(this.appointmentBookingService.bookingFinalizeDraftAppointmentInsured(appointmentId, paymentDetails));
  }

  async confirmReservedAppointmentSlotWithStripe(appointmentId: string, paymentDetails: PaymentDetailsWithStripe): Promise<AppointmentBooked> {
    return firstValueFrom(this.appointmentBookingService.bookingFinalizeDraftAppointmentPrivatePay(appointmentId, paymentDetails));
  }

  async extendReservedAppointmentSlot(draftAppointment: DraftAppointmentSlot): Promise<DraftAppointmentSlot> {
    const { appointmentId, patientId, familyMemberId } = draftAppointment;
    const updateAppointmentDraftRequest = {
      appointmentId,
      patientId,
      familyMemberId,
    } as AppointmentDraftUpdateRequest;
    const appointmentDraftResponse = await firstValueFrom(this.appointmentBookingService.updateAppointmentDraft(appointmentId, updateAppointmentDraftRequest));

    return this.getDraftAppointmentSlotFromResponse(appointmentDraftResponse);
  }

  async extendCurrentReservedAppointmentSlot(): Promise<void> {
    const currentReservedAppointmentSlot = await firstValueFrom(this._currentReservedAppointmentSlot);
    if (currentReservedAppointmentSlot !== undefined) {
      if (currentReservedAppointmentSlot.canDraftAppointmentExpiryBeExtended === false) {
        throw new Error(AppointmentReservationService.CANNOT_EXTEND_TIME_SLOT_EXPIRY_ERROR_MESSAGE);
      }

      const extendedReservedAppointmentSlot = await this.extendReservedAppointmentSlot(currentReservedAppointmentSlot);
      this._currentReservedAppointmentSlot.next(extendedReservedAppointmentSlot);

      if (currentReservedAppointmentSlot.canDraftAppointmentExpiryBeExtended === true && extendedReservedAppointmentSlot.canDraftAppointmentExpiryBeExtended === false) {
        throw new Error(AppointmentReservationService.CANNOT_EXTEND_TIME_SLOT_EXPIRY_ERROR_MESSAGE);
      }
    }
  }

  getCurrentReservedAppointmentSlot(): Observable<DraftAppointmentSlot | undefined> {
    return this._currentReservedAppointmentSlot.asObservable().pipe(shareReplay(1));
  }

  clearCurrentReservedAppointmentSlot(): void {
    this._currentReservedAppointmentSlot.next(undefined);
  }

  async deleteAppointmentDraft(draftAppointmentId: string): Promise<void> {
    try {
      await firstValueFrom(this.appointmentBookingService.deleteAppointmentDraft(draftAppointmentId));
      this.clearCurrentReservedAppointmentSlot();
    } catch (error) {
      console.error(error);
    }
  }

  setCurrentReservedAppointmentSlot(draftAppointmentSlot: DraftAppointmentSlot): void {
    this._currentReservedAppointmentSlot.next(draftAppointmentSlot);
  }

  getDraftAppointmentSlotFromResponse(draftAppointmentResponse: AppointmentDraftResponse): DraftAppointmentSlot {
    if (draftAppointmentResponse.province === undefined) {
      throw new Error('Appointment draft response has no province');
    }

    if (draftAppointmentResponse.appointmentDraftId === undefined) {
      throw new Error('Appointment draft response has no appointmentDraftId');
    }

    if (draftAppointmentResponse.doctorId === undefined) {
      throw new Error('Appointment draft response has no doctorId');
    }

    if (draftAppointmentResponse.companyId === undefined) {
      throw new Error('Appointment draft response has no companyId');
    }

    if (draftAppointmentResponse.locationId === undefined) {
      throw new Error('Appointment draft response has no locationId');
    }

    if (draftAppointmentResponse.billingType === undefined) {
      throw new Error('Appointment draft response has no billingType');
    }

    if (draftAppointmentResponse.isServiceCharged === undefined) {
      throw new Error('Appointment draft response has no isServiceCharged');
    }

    if (draftAppointmentResponse.serviceId === undefined) {
      throw new Error('Appointment draft response has no serviceId');
    }

    if (draftAppointmentResponse.serviceMedium === undefined) {
      throw new Error('Appointment draft response has no serviceMedium');
    }

    if (draftAppointmentResponse.eventStart === undefined) {
      throw new Error('Appointment draft response has no eventStart');
    }

    if (draftAppointmentResponse.draftExpiry === undefined) {
      throw new Error('Appointment draft response has no draftExpiry');
    }

    if (draftAppointmentResponse.secondsToDraftExpiry === undefined) {
      throw new Error('Appointment draft response has no secondsToDraftExpiry');
    }

    if (draftAppointmentResponse.stripePaymentIntentClientSecret === undefined) {
      throw new Error('Appointment draft response has no stripePaymentIntentClientSecret');
    }

    const localExpiryTime = this.getLocalExpiryTime(
      new Date().getTime(),
      new Date(`${draftAppointmentResponse.draftExpiry}Z`).getTime(),
      draftAppointmentResponse.secondsToDraftExpiry,
    );

    return {
      patientId: draftAppointmentResponse.patientId,
      province: this.provinceService.convertFromServerProvince(draftAppointmentResponse.province),
      appointmentId: draftAppointmentResponse.appointmentDraftId,
      familyMemberId: draftAppointmentResponse.familyMemberId,
      doctorId: draftAppointmentResponse.doctorId,
      companyId: draftAppointmentResponse.companyId,
      locationId: draftAppointmentResponse.locationId,
      billingType: draftAppointmentResponse.billingType,
      isServiceCharged: draftAppointmentResponse.isServiceCharged,
      errorCodes: this.parseDraftErrorCodes(draftAppointmentResponse.errorCodeList),
      price: draftAppointmentResponse.price ? parseFloat(draftAppointmentResponse.price) : undefined,
      serviceId: draftAppointmentResponse.serviceId,
      serviceMedium: parseServiceMedium(draftAppointmentResponse.serviceMedium),
      availableServiceMediums: this.appointmentMediumService.parseAppointmentMediums(draftAppointmentResponse?.serviceMediumSelectionsList ?? []),
      startTime: new Date(`${draftAppointmentResponse.eventStart}Z`).getTime(),
      expiryTime: localExpiryTime,
      canDraftAppointmentExpiryBeExtended: draftAppointmentResponse.draftExpiryTimeExtensionEnabled,
      stripePaymentIntentClientSecret: draftAppointmentResponse.stripePaymentIntentClientSecret,
      discountedPrice: draftAppointmentResponse.discountedPrice,
      tooManyAppointmentsError: this.getTooManyAppointmentsErrorFromDraftAppointmentResponse(draftAppointmentResponse),
    };
  }

  getLocalExpiryTime(localTime: number, serverExpiryTime: number, serverSecondsToExpiry: number): number {
    const serverTime = serverExpiryTime - serverSecondsToExpiry * MILLISECONDS_PER_SECOND;
    const clockSkew = serverTime - localTime;
    return serverExpiryTime - clockSkew;
  }

  parseDraftErrorCodes(errorCodes: ErrorCode[] | undefined): DraftErrorCode[] {
    if (errorCodes) {
      return errorCodes.map(this.parseDraftErrorCode.bind(this));
    } else {
      return [];
    }
  }

  parseDraftErrorCode(errorCode: ErrorCode): DraftErrorCode {
    switch (errorCode) {
      case ErrorCode.DraftAlreadyExists:
        return DraftErrorCode.DRAFT_ALREADY_EXISTS;

      case ErrorCode.AvailabilityBlockDoesNotExist:
        return DraftErrorCode.AVAILABILITY_BLOCK_DOES_NOT_EXIST;

      case ErrorCode.DiscountCodeAlreadyUsed:
        return DraftErrorCode.DISCOUNT_CODE_ALREADY_USED;

      case ErrorCode.DiscountCodeGetRecord:
        return DraftErrorCode.DISCOUNT_CODE_GET_RECORD;

      case ErrorCode.DiscountCodeDoesNotExist:
        return DraftErrorCode.DISCOUNT_CODE_DOES_NOT_EXIST;

      case ErrorCode.Forbidden:
        return DraftErrorCode.FORBIDDEN;

      case ErrorCode.BadRequest:
        return DraftErrorCode.BAD_REQUEST;

      case ErrorCode.InternalServerError:
        return DraftErrorCode.INTERNAL_SERVER_ERROR;

      case ErrorCode.DraftAppointmentNotFound:
        return DraftErrorCode.DRAFT_APPOINTMENT_NOT_FOUND;

      case ErrorCode.ClinicConstraintFailed:
        return DraftErrorCode.CLINIC_CONSTRAINT_FAILED;

      case ErrorCode.InvalidMedium:
        return DraftErrorCode.INVALID_MEDIUM;

      case ErrorCode.MediumNotFound:
        return DraftErrorCode.MEDIUM_NOT_FOUND;

      case ErrorCode.RecordNotFound:
        return DraftErrorCode.RECORD_NOT_FOUND;

      case ErrorCode.BookAppointmentTimeout:
        return DraftErrorCode.BOOK_APPOINTMENT_TIMEOUT;

      case ErrorCode.BookAppointmentError:
        return DraftErrorCode.BOOK_APPOINTMENT_ERROR;

      case ErrorCode.PaymentChargeFailed:
        return DraftErrorCode.PAYMENT_CHARGE_FAILED;

      case ErrorCode.InvalidHealthcard:
        return DraftErrorCode.INVALID_HEALTHCARD;

      case ErrorCode.InvalidService:
        return DraftErrorCode.INVALID_SERVICE;

      case ErrorCode.DoctorNotFound:
        return DraftErrorCode.DOCTOR_NOT_FOUND;

      case ErrorCode.DraftInvalidForDoctor:
        return DraftErrorCode.DRAFT_INVALID_FOR_DOCTOR;

      case ErrorCode.PatientNotFound:
        return DraftErrorCode.PATIENT_NOT_FOUND;

      case ErrorCode.RequiredFieldMissing:
        return DraftErrorCode.REQUIRED_FIELD_MISSING;

      case ErrorCode.InvalidFieldEntry:
        return DraftErrorCode.INVALID_FIELD_ENTRY;

      case ErrorCode.MaxPublicBookingsReached:
        return DraftErrorCode.MAX_PUBLIC_BOOKINGS_REACHED;

      case ErrorCode.PatientProfileIncomplete:
        return DraftErrorCode.PATIENT_PROFILE_INCOMPLETE;

      case ErrorCode.OtherError:
        return DraftErrorCode.OTHER_ERROR;

      default:
        return DraftErrorCode.OTHER_ERROR;
    }
  }

  private getServerBookingSite(bookingSite: InsigBookingSite): BookingSite {
    switch (bookingSite) {
      case InsigBookingSite.TIA:
        return BookingSite.TIA_HEALTH;
      case InsigBookingSite.BC:
        return BookingSite.BC_HEALTH;
      default:
        return BookingSite.OTHER;
    }
  }

  getTooManyAppointmentsErrorFromDraftAppointmentResponse(draftAppointmentResponse: AppointmentDraftResponse): TooManyAppointmentsError {
    return {
      tooManyAppointmentsInOneDay: draftAppointmentResponse.errorCodeList ? draftAppointmentResponse.errorCodeList.includes(ErrorCode.MaxPublicBookingsReached) : false,
      familyMemberId: draftAppointmentResponse.familyMemberId,
    };
  }
}
