<ng-container [formGroup]="familyDoctorForm">
  <div [ngClass]="!isPatientFamilyDoctorInfoValid(patientProfile) && faxNotesToFamilyDoctor ? 'd-inline' : 'd-none'">
    <span class="bold">Family Doctor</span>
    <div class="d-flex gap-1 py-2">
      <insig-ui-text-input
        label="First Name"
        isRequired="true"
        [formControlName]="FAMILY_DOCTOR_FIRST_NAME_FORM_CONTROL_NAME"
      ></insig-ui-text-input>
      <insig-ui-text-input
        label="Last Name"
        isRequired="true"
        [formControlName]="FAMILY_DOCTOR_LAST_NAME_FORM_CONTROL_NAME"
      ></insig-ui-text-input>
      <insig-ui-text-input
        label="Fax Number"
        isRequired="true"
        [formControlName]="FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME"
      ></insig-ui-text-input>
    </div>
    <span *ngIf="familyDoctorForm.invalid" class="textPink fw-normal py-2">
      {{ WARNING_GLYPH }} Family doctor information invalid, please update to continue.
    </span>
    <div>
      <insig-ui-button
        [disabled]="familyDoctorForm.invalid"
        iconSrc="assets/img/icon_save_white.svg"
        (click)="handleSaveButtonClicked(familyDoctorForm)"
        (keypress)="handleSaveButtonClicked(familyDoctorForm)"
      >
        Save
      </insig-ui-button>
    </div>
  </div>
</ng-container>
