import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { CompanyBookingComponent } from '../../../company-booking/company-booking.component';
import { Region } from '@insig-health/services/location/location.service';
import { Province, ProvinceService } from '@insig-health/services/province/province.service';
import { BillingRegion, BillingRegionService, RegionType } from 'apps/insig-booking/src/services/billing-region/billing-region.service';
import { BillingType } from 'apps/insig-booking/src/services/billing-type/billing-type.service';
import { BookingStep, BookingStepService } from 'apps/insig-booking/src/services/booking-step/booking-step.service';
import { CurrentProvinceService } from 'apps/insig-booking/src/services/current-province/current-province.service';

export enum ProvinceSelectionState {
  CHOOSE_PROVINCE,
  CHOOSE_PAYMENT_METHOD,
}

export interface ProvinceSelectionDialogOptions {
  region?: Region;
  replaceUrl?: boolean;
}

export const PROVINCE_SELECTION_DIALOG_CONFIG = {
  maxWidth: '800px',
  width: '600px',
  autoFocus: false,
};

@Component({
  selector: 'insig-booking-province-selection-dialog',
  templateUrl: './province-selection-dialog.component.html',
})
export class ProvinceSelectionDialogComponent implements OnInit {
  public ProvinceSelectionState = ProvinceSelectionState;

  public provinceSelectionDialogState = ProvinceSelectionState.CHOOSE_PAYMENT_METHOD;

  public selectedProvince: Province | undefined;
  public isInternationalPatient = false;

  public regions$ = this.currentProvinceService.getRegionList();

  constructor(
    private dialogRef: MatDialogRef<ProvinceSelectionDialogComponent>,
    public provinceService: ProvinceService,
    public billingRegionService: BillingRegionService,
    private currentProvinceService: CurrentProvinceService,
    private bookingStepService: BookingStepService,
    private activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: ProvinceSelectionDialogOptions,
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      if (this.data.region) {
        this.selectedProvince = this.provinceService.parseQueryParamProvince(this.data.region.regionAbbreviation);
      } else {
        this.provinceSelectionDialogState = ProvinceSelectionState.CHOOSE_PROVINCE;
      }
    } catch (error) {
      this.isInternationalPatient = true;
    }
  }

  getProvinces(): string[] {
    return Object.keys(Province);
  }

  getProvinceName(provinceAbbreviation: string | Province): string {
    const provinceValue = this.provinceService.parseQueryParamProvince(provinceAbbreviation);

    return this.provinceService.getProvinceName(provinceValue);
  }

  getInternationalRegionName(region: Region): string {
    return `${region.regionName}, ${region.countryName ?? region.countryAbbreviation}`;
  }

  handleRegionSelectionClick(region: Region): void {
    try {
      const selectedProvince = this.provinceService.parseQueryParamProvince(region.regionAbbreviation);
      this.selectedProvince = selectedProvince;

      if(!this.provinceService.isProvinceAvailableForPublicAppointments(selectedProvince)) {
        const billingRegion: BillingRegion = {
          type: RegionType.PROVINCE,
          provinceAbbreviation: selectedProvince,
          billingType: BillingType.PRIVATE,
        };
        this.updateProvinceAndBillingTypePathParams(billingRegion);
        this.dialogRef.close(billingRegion);
      } else {
        this.provinceSelectionDialogState = ProvinceSelectionState.CHOOSE_PAYMENT_METHOD;
      }
    } catch (error) {
      this.data = { region };
      this.isInternationalPatient = true;
      this.provinceSelectionDialogState = ProvinceSelectionState.CHOOSE_PAYMENT_METHOD;
    }
  }

  private updateProvinceAndBillingTypePathParams(billingRegion: BillingRegion): void {
    const deepestRoute = this.bookingStepService.getDeepestActivatedRouteChild(this.activatedRoute);
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(deepestRoute, [CompanyBookingComponent]);

    const navigationExtras: NavigationExtras = {
      relativeTo: companyBookingRoute,
      queryParams: {
        doNotShowBillingAndProvinceDialog: 'true',
        doctorId: null,
        appointmentType: null,
      },
      replaceUrl: this.data.replaceUrl,
    };

    this.bookingStepService.jumpToStep(BookingStep.CHOOSE_DOCTOR, {
      navigationExtras: navigationExtras,
      pathParams: { region: billingRegion },
    });
  }

  handleChangeLocationButtonClick(): void {
    this.provinceSelectionDialogState = ProvinceSelectionState.CHOOSE_PROVINCE;
  }

  handleBackButtonClick(): void {
    this.provinceSelectionDialogState = ProvinceSelectionState.CHOOSE_PAYMENT_METHOD;
  }

  handlePayPrivatelyAsInternationalPatientButtonClick(): void {
    const billingRegion = this.billingRegionService.getBillingRegion('international', BillingType.PRIVATE);
    this.updateProvinceAndBillingTypePathParams(billingRegion);
    this.dialogRef.close(billingRegion);
  }

  handlePayPrivatelyButtonClick(province: Province): void {
    const billingRegion = this.billingRegionService.getBillingRegion(province, BillingType.PRIVATE);
    this.updateProvinceAndBillingTypePathParams(billingRegion);
    this.dialogRef.close(billingRegion);
  }

  handleValidHealthCardButtonClick(province: Province): void {
    const billingRegion: BillingRegion = {
      type: RegionType.PROVINCE,
      provinceAbbreviation: province,
      billingType: BillingType.PUBLIC,
    };
    this.updateProvinceAndBillingTypePathParams(billingRegion);
    this.dialogRef.close(billingRegion);
  }
}
