import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { FamilyMemberProfile, FamilyMemberService } from '@insig-health/services/family-member/family-member.service';
import { PatientProfile, PatientProfileService } from '@insig-health/services/patient-profile/patient-profile.service';
import { FamilyMemberRequest } from '@insig-health/api/patient-management-api';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/angular-material.config';
import { HttpErrorResponse } from '@angular/common/http';
import { FormValidatorsService } from '@insig-health/services/form-validators/form-validators.service';
import { RegexService } from '@insig-health/services/regex/regex.service';
import { HtmlConstants } from '@insig-health/constants/html-constants';

export type Profile = PatientProfile | FamilyMemberProfile;

@Component({
  selector: 'insig-booking-family-doctor-form',
  templateUrl: './family-doctor-form.component.html',
})
export class FamilyDoctorFormComponent implements OnInit {
  public static readonly DEFAULT_ERROR_MESSAGE = 'An error was encountered while updating the family doctor information';

  public readonly FAMILY_DOCTOR_FIRST_NAME_FORM_CONTROL_NAME = 'familyDoctorFirstName';
  public readonly FAMILY_DOCTOR_LAST_NAME_FORM_CONTROL_NAME = 'familyDoctorLastName';
  public readonly FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME = 'familyDoctorFaxNumber';
  public familyDoctorForm = new FormGroup({
    [this.FAMILY_DOCTOR_FIRST_NAME_FORM_CONTROL_NAME]: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    [this.FAMILY_DOCTOR_LAST_NAME_FORM_CONTROL_NAME]: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    [this.FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME]: new FormControl('', { nonNullable: true, validators: [Validators.required, this.formValidatorsService.isPhoneNumberValidValidator(false)] }),
  });

  _patientProfile: Profile | undefined;
  get patientProfile(): Profile | undefined {
    return this._patientProfile;
  }
  @Input() set patientProfile(value: Profile | undefined) {
    this._patientProfile = value;
    if (value) {
      this.autofillFamilyDoctorDetails(value);
    }
  }
  @Input() faxNotesToFamilyDoctor = false;

  @Output() familyDoctorSaved = new EventEmitter();

  public WARNING_GLYPH = HtmlConstants.WARNING_SIGN;

  constructor(
    private familyMemberService: FamilyMemberService,
    private formValidatorsService: FormValidatorsService,
    private patientProfileService: PatientProfileService,
    private regexService: RegexService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    if (this.patientProfile) {
      this.autofillFamilyDoctorDetails(this.patientProfile);
    }
  }

  autofillFamilyDoctorDetails(patientProfile: PatientProfile | FamilyMemberProfile): void {
    this.familyDoctorForm.patchValue({
      [this.FAMILY_DOCTOR_FIRST_NAME_FORM_CONTROL_NAME]: patientProfile.familyDoctorFirstName,
      [this.FAMILY_DOCTOR_LAST_NAME_FORM_CONTROL_NAME]: patientProfile.familyDoctorLastName,
      [this.FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME]: patientProfile.familyDoctorFaxNumber,
    });
  }

  async handleSaveButtonClicked(familyDoctorForm: FormGroup): Promise<void> {
    if (this.patientProfile === undefined) {
      return;
    }

    try {
      if (this.isPatientProfile(this.patientProfile)) {
        await this.patientProfileService.setCurrentUserPatientProfile({
          ...this.patientProfile,
          familyDoctorFirstName: familyDoctorForm.get(this.FAMILY_DOCTOR_FIRST_NAME_FORM_CONTROL_NAME)?.value,
          familyDoctorLastName: familyDoctorForm.get(this.FAMILY_DOCTOR_LAST_NAME_FORM_CONTROL_NAME)?.value,
          familyDoctorFaxNumber: this.getUnformattedPhoneNumber(familyDoctorForm.get(this.FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME)?.value),
        });
      } else {
        const editFamilyMemberRequest: FamilyMemberRequest = {
          first: this.patientProfile.firstName,
          last: this.patientProfile.lastName,
          day: this.patientProfile.day,
          month: this.patientProfile.month,
          year: this.patientProfile.year,
          gender: this.patientProfile.gender,
          address: this.patientProfile.address,
          city: this.patientProfile.city,
          province: this.patientProfile.province,
          phone: this.patientProfile.phone,
          familyDoctorFirstName: familyDoctorForm.get(this.FAMILY_DOCTOR_FIRST_NAME_FORM_CONTROL_NAME)?.value,
          familyDoctorLastName: familyDoctorForm.get(this.FAMILY_DOCTOR_LAST_NAME_FORM_CONTROL_NAME)?.value,
          familyDoctorFaxNumber: this.getUnformattedPhoneNumber(familyDoctorForm.get(this.FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME)?.value),
        };
        await this.familyMemberService.setFamilyMember(this.patientProfile.patientId, this.patientProfile.familyMemberId, editFamilyMemberRequest);
      }
      this.familyDoctorSaved.emit();
    } catch (error) {
      console.error(error);
      this.snackBar.open(
        (error as HttpErrorResponse).error?.errorMessage ?? FamilyDoctorFormComponent.DEFAULT_ERROR_MESSAGE,
        undefined,
        { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS },
      );
    }
  }

  getUnformattedPhoneNumber(phoneNumber: string): string {
    return phoneNumber.replace(this.regexService.getNonDigitGlobalRegex(), '');
  }

  isPatientProfile(patientProfile: Profile): patientProfile is PatientProfile {
    return (patientProfile as PatientProfile).uid !== undefined;
  }

  isPatientFamilyDoctorInfoValid(patientProfile: Profile | undefined): boolean {
    return patientProfile !== undefined &&
      patientProfile.familyDoctorFirstName !== '' &&
      patientProfile.familyDoctorLastName !== '' &&
      patientProfile.familyDoctorFaxNumber !== '' &&
      this.formValidatorsService.isPhoneNumberValidValidator(false)(new FormControl(patientProfile.familyDoctorFaxNumber)) === null;
  }
}
