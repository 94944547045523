import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'insig-booking-error-dialog',
  templateUrl: './error-dialog.component.html',
})
export class ErrorDialogComponent {
  public readonly GENERIC_ERROR_MESSAGE = 'Please refresh your page or try again later.';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { error?: Error },
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
  ) {}
}
