import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/angular-material.config';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';
import { AppointmentReservationService, DraftErrorCode } from 'apps/insig-booking/src/services/appointment-reservation/appointment-reservation.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'insig-booking-discount-code-form',
  templateUrl: './discount-code-form.component.html',
})
export class DiscountCodeFormComponent {
  public static readonly GENERIC_DISCOUNT_CODE_ERROR_MESSAGE = 'An error was encountered when applying the discount code.';
  public static readonly DISCOUNT_CODE_DOES_NOT_EXIST_ERROR_MESSAGE = 'That discount code does not exist.';
  public static readonly NO_APPOINTMENT_ERROR_MESSAGE = 'No reserved appointment to apply discount to.';

  public readonly DISCOUNT_CODE_FORM_CONTROL_NAME = 'discountCode';
  public discountCodeForm = new FormGroup({
    [this.DISCOUNT_CODE_FORM_CONTROL_NAME]: new FormControl('', {}),
  });

  @Output() discountedPriceChange = new EventEmitter<number>();

  constructor(
    private appointmentReservationService: AppointmentReservationService,
    private gcpIpAuthService: GcpIpAuthService,
    private snackBar: MatSnackBar,
  ) {}

  async handleApplyDiscountButtonClicked(discountCode: string): Promise<void> {
    try {
      const reservedAppointment = await firstValueFrom(this.appointmentReservationService.getCurrentReservedAppointmentSlot());
      if (reservedAppointment === undefined) {
        throw new Error(DiscountCodeFormComponent.NO_APPOINTMENT_ERROR_MESSAGE);
      }

      const uid = this.gcpIpAuthService.getCurrentUser()?.uid;
      if (!uid) {
        return;
      }
      const discountedPrice = await this.getDiscountedPriceForDiscountCode(reservedAppointment.appointmentId, discountCode);
      this.openSnackBar('Applied discount.', SNACK_BAR_AUTO_DISMISS_MILLISECONDS);
      this.discountedPriceChange.emit(discountedPrice);
    } catch (error) {
      console.error(error);
      this.discountCodeForm.controls[this.DISCOUNT_CODE_FORM_CONTROL_NAME].setErrors({
        discountCodeInvalid: true,
      });
      let errorMessage;
      if (error instanceof Error) {
        errorMessage = error.message;
      }

      this.openSnackBar(errorMessage ?? DiscountCodeFormComponent.GENERIC_DISCOUNT_CODE_ERROR_MESSAGE, SNACK_BAR_AUTO_DISMISS_MILLISECONDS);
    }
  }

  async getDiscountedPriceForDiscountCode(appointmentId: string, discountCode: string): Promise<number> {
    const discountedAppointmentDraft = await this.appointmentReservationService.updateReservedAppointmentSlot(appointmentId, { discountCode });

    if (discountedAppointmentDraft.errorCodes?.includes(DraftErrorCode.DISCOUNT_CODE_DOES_NOT_EXIST) || discountedAppointmentDraft.discountedPrice === undefined || discountedAppointmentDraft.discountedPrice === null) {
      throw new Error(DiscountCodeFormComponent.DISCOUNT_CODE_DOES_NOT_EXIST_ERROR_MESSAGE);
    }

    const discountedPrice = discountedAppointmentDraft.discountedPrice;

    return discountedPrice;
  }

  openSnackBar(message: string, durationInMilliseconds: number): void {
    this.snackBar.open(message, undefined, { duration: durationInMilliseconds });
  }
}
