import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormValidatorsService } from '@insig-health/services/form-validators/form-validators.service';

import { PatientProfile } from '@insig-health/services/patient-profile/patient-profile.service';

@Component({
  selector: 'insig-booking-pharmacy-info-form',
  templateUrl: './pharmacy-info-form.component.html',
})
export class PharmacyInfoFormComponent implements OnInit {

  public static readonly PHARMACY_FORM_NAME = 'pharmacyInfoForm';

  @Input() parentForm: UntypedFormGroup | undefined;
  @Input() patientProfile: PatientProfile | undefined;

  public readonly PHARMACY_NAME_FORM_CONTROL_NAME = 'pharmacyName';
  public readonly PHARMACY_FAX_NUMBER_FORM_CONTROL_NAME = 'pharmacyFaxNumber';

  public pharmacyInfoForm = this.formBuilder.group({
    [this.PHARMACY_NAME_FORM_CONTROL_NAME]: ['', [Validators.required]],
    [this.PHARMACY_FAX_NUMBER_FORM_CONTROL_NAME]: ['', [Validators.required, this.formValidatorsService.isPhoneNumberValidValidator(false)]],
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private formValidatorsService: FormValidatorsService,
  ) {}

  ngOnInit(): void {
    if (this.parentForm) {
      this.registerFormAsParentToChild(this.parentForm, this.pharmacyInfoForm, PharmacyInfoFormComponent.PHARMACY_FORM_NAME);
    }

    if (this.patientProfile) {
      this.prepopulatePharmacyInfoFormFromPatientProfile(this.pharmacyInfoForm, this.patientProfile);
    }
  }

  registerFormAsParentToChild(parentForm: UntypedFormGroup, childForm: UntypedFormGroup, childFormName: string): void {
    parentForm.addControl(childFormName, childForm);
    childForm.setParent(parentForm);
  }

  prepopulatePharmacyInfoFormFromPatientProfile(form: UntypedFormGroup, patientProfile: PatientProfile): void {
    form.patchValue({
      [this.PHARMACY_NAME_FORM_CONTROL_NAME]: patientProfile.pharmacyName,
      [this.PHARMACY_FAX_NUMBER_FORM_CONTROL_NAME]: patientProfile.pharmacyFax,
    });
  }
}
