<h1 matDialogTitle class="pt-0 pb-2">
  <img class="w-25" src="/assets/images/pillway-logo.png" alt="Pillway Logo" />
</h1>

<div mat-dialog-content>
  <div style="color:#e34429;" class="h2 bold">Easy as 1 2 3</div>
  <br>
  <p>
    1. Select Pillway as your preferred pharmacy, and you prescription will be sent after your appointment.
  </p>
  <br>
  <p>
    2. Pillway will notify you within 1 business hour and arrange delivery. For same-day delivery, prescriptions must be sent to the pharmacy before 3 PM Mon-Fri. Same-day service is available in the Greater Toronto Area and Greater Vancouver Area only, for the time being. Weekend deliveries will be shipped the next business day.
  </p>
  <br>
  <p>
    3. Your prescription will be delivered to your door! Download the free Pillway app to connect to the Pillway pharmacy team, check status updates, and more.
  </p>
  <br>
  <p>
    By choosing Pillway as your preferred pharmacy, you authorize the sending of your prescription, and contact info to Pillway. You also authorize Pillway to send you email and/or SMS.
  </p>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>
    Close
  </button>
</mat-dialog-actions>
